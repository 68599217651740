import React, { useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';
import { useColumns } from './useColumns';

interface TradesTableProps {
  trades: Trade[];
}

export const TradesTable = ({ trades }: TradesTableProps) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const { columns } = useColumns();

  const table = useReactTable({
    columns,
    data: trades,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'start_date', desc: false }] },
  });

  const pageSize = pagination.pageSize;
  const pageCount = table.getPageCount();

  return (
    <div>
      <Table data-cy="trades-table">
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {trades.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={trades.length} />
          <TablePagination pageCount={pageCount} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </div>
  );
};
