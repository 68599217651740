import { ContractSubType } from '../contract_sub_type';
import { Currency } from '../currency';
import MarketPlayer from '../market_player';
import { SystemDate } from '../string-helpers';
import { HedgeContract } from './hedge';
import { PaymentDelay } from './paymentDelay';
import { ContractInstallation, ContractSubPeriod } from './sub-period';
import {
  AllocationRound,
  CRFacturationType,
  ContractDispositif,
  ContractIndexTimePeriod,
  ContractType,
  InvoicingPlatform,
  ProductType,
} from './unions';

export type Contract = {
  aggregation_fee: number;
  annual_cap: number;
  allocation_round: AllocationRound | null;
  birthday_date: string | Date | null;
  buyer: MarketPlayer;
  calc_base: 'load_curve' | 'index';
  card_i: string;
  cfd_lccd: string | null;
  client_end_date: string;
  clipping: boolean;
  contract_nb: string;
  contract_sub_periods: ContractSubPeriod[];
  cr_facturation_type: CRFacturationType;
  credential_id: string | null;
  cut_time: '00:00' | '02:00';
  dispositif: ContractDispositif | null;
  dummy: boolean;
  edf_agency_id: number | null;
  end_date: string | Date;
  erp_code: string | null;
  external_pdf_link?: string | null;
  financement_participatif: boolean;
  management_bonus?: number | null;
  hedge_contract: HedgeContract;
  id: string;
  index_formula: string | null;
  installation: ContractInstallation;
  investissement_participatif: boolean;
  invoicing_period?: ContractIndexTimePeriod;
  invoicing_platform?: InvoicingPlatform | null;
  labour_cost_insee_index_ref: string | null;
  latest_unit_price: number | null;
  ncc: number | null;
  negative_price: boolean;
  p_max: number;
  producer_price_insee_index_ref: string;
  product_type: ProductType;
  rate_change: null | 'commissioning_date' | '1st_nov' | '1st_jan';
  seller: MarketPlayer;
  sent_to_remit: boolean;
  signature_date: SystemDate | null;
  start_date: string | Date;
  status: 'draft' | 'dummy' | 'not_signed' | 'complete';
  sub_type: ContractSubType | null;
  type: ContractType;
  updated_at: string;
  currency: Currency;
  power_augmentation_percentage: number | null;
  power_augmentation_start_date: string | Date | null;
  is_indexed: boolean | null;
  above_cap_price: number | null;
  balancing_responsible_party?: MarketPlayer;
  additional_buyer?: MarketPlayer | null;
  include_vat: boolean;
  payment_delay: PaymentDelay;
  direction: 'sell' | 'buy';
  delivery_profile: DeliveryProfile | null;
  scheme: 'supply_only' | 'unique_contract' | null;
};

export const DELIVERY_PROFILES = [
  'pay_as_consumed',
  'pay_as_produced',
  'pay_as_percentage_based_profile',
  'pay_as_nominated',
  'pay_as_fixed_profile',
] as const;

export const PRODUCER_SELL_DELIVERY_PROFILES: DeliveryProfile[] = [
  'pay_as_produced',
  'pay_as_percentage_based_profile',
  'pay_as_nominated',
  'pay_as_fixed_profile',
];

export const PRODUCER_BUY_DELIVERY_PROFILES: DeliveryProfile[] = ['pay_as_consumed', 'pay_as_percentage_based_profile'];

export type DeliveryProfile = typeof DELIVERY_PROFILES[number];

export type Classification = 'physical' | 'financial';

export const CONTRACT_WITH_DELIVERY_PROFILE: readonly ContractType[] = [
  'ContractSoa',
  'ContractCppa',
  'ContractSupplyUppa',
  'ContractSupplyCppa',
];
