import { getBuyerPlayers, getMarketPlayers } from '@api/marketPlayers';
import { useQuery } from '@tanstack/react-query';

export const useMarketPlayers = () => {
  return useQuery({
    queryKey: ['market_players'],
    queryFn: () => getMarketPlayers(),
  });
};

export const useBuyerPlayers = (with_unavailabilities_info?: boolean) => {
  return useQuery({
    queryKey: ['buyers'],
    queryFn: () => getBuyerPlayers(with_unavailabilities_info),
  });
};
