import { createTrade, getTrades } from '@api/trades';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Trade } from '@utils/types/trade';
import { TradeBody } from '@utils/types/trade_body';

export const useTrades = () => {
  return useQuery<Trade[], Error>({
    queryKey: ['trades'],
    queryFn: getTrades,
  });
};

export const useTradesCreateMutation = (toggle: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['trades'],
    mutationFn: (trade: TradeBody) => createTrade(trade),
    onSuccess: (trade) => {
      queryClient.setQueryData<Trade[]>(['trades'], (trades) => {
        const clonedTrades = trades ? [...trades] : [];
        clonedTrades?.push(trade);

        toggle();

        return clonedTrades;
      });
    },
  });
};
