import React, { forwardRef } from 'react';
import { Button } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import LegacySelect, { ActionMeta, components, MultiValue, SingleValue } from 'react-select';
import { GroupedSelectMultiProps, GroupedSelectSingleProps } from './grouped-select.types';
import styles from './select.module.scss';

function BaseGroupedSelect<T extends string>(
  props: GroupedSelectMultiProps<T> | GroupedSelectSingleProps<T>,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const { t } = useTranslation();
  const classList = classNames(styles.select, props.size && styles[props.size], props.className, {
    [styles['is-multi-select']]: props.isMulti,
  });
  const id = uniqueId();

  const selectAll = () => {
    if (!props.isMulti) return;

    const selected = props.options
      .flatMap((opt) => {
        if (opt && Object.prototype.hasOwnProperty.call(opt, 'options')) {
          return opt?.options;
        }

        return null;
      })
      .filter((opt): opt is Option<T> => !!opt);

    props.onChange(selected);
  };

  return (
    <div className={classList} ref={ref}>
      {(!!props.label || props.hasSelectAll) && (
        <label htmlFor={id}>
          <span className={styles['label-text']}>{t(props.label)}</span>
          {props.labelButton && (
            <Button onClick={props.onClickLabelButton} variant="link" className="ml-2" size="xs">
              {t(props.labelButton)}
            </Button>
          )}
          {props.isLoading && <Spinner className={classNames(styles.spinner, 'ml-2')} size="sm" />}
          {props.hasSelectAll && props.isMulti && (
            <Button
              onClick={selectAll}
              variant="link"
              size="xs"
              text="common.select_all"
              className="ml-2"
              disabled={Array.isArray(props.value) && props.value.length === props.options.length}
            />
          )}
        </label>
      )}
      <LegacySelect
        name={props.name}
        inputId={id}
        options={props.options}
        isSearchable={props.isSearchable}
        onChange={
          props.onChange as (newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => void
        }
        value={props.value}
        classNamePrefix={props.classNamePrefix}
        unstyled
        isDisabled={props.isDisabled}
        components={{
          Option: (props) => {
            return <components.Option {...props}>{t(props.data.label)}</components.Option>;
          },
          GroupHeading: (props) => {
            return <components.GroupHeading {...props}>{t(props.data.label)}</components.GroupHeading>;
          },
          ...props.components,
        }}
        classNames={{
          control: (state) =>
            classNames(
              styles.control,
              state.isDisabled && styles['control-disabled'],
              props.hasError && styles['has-error'],
            ),
          indicatorSeparator: () => styles['indicator-separator'],
          menu: () => styles.menu,
          option: (state) =>
            classNames(
              styles.option,
              state.isSelected && styles['option-selected'],
              state.isFocused && styles['option-focused'],
            ),
          placeholder: () => styles.placeholder,
          clearIndicator: () => styles['clear-indicator'],
          multiValue: () => styles['multi-value'],
          valueContainer: () => styles['value-container'],
          groupHeading: () => styles['group-heading'],
        }}
        placeholder={t(props.placeholder || 'common.select_default_placeholder')}
        isMulti={props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        id={id}
        isOptionDisabled={props.isOptionDisabled}
        isClearable={props.isClearable}
        formatOptionLabel={props.formatOptionLabel}
      />
      {props.errorMessage && <Text size="sm" className="mt-1" type="danger" text={props.errorMessage} />}
    </div>
  );
}
/**
 * TODO: Check if it's mergeable with Select, for now types are not compatible
 */
export const GroupedSelect = forwardRef(BaseGroupedSelect) as <T>(
  props: GroupedSelectSingleProps<T> | GroupedSelectMultiProps<T>,
) => JSX.Element;
