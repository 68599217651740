import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export const Price = ({ row: { original } }: CellContext<Trade, unknown>) => {
  if (original?.price || original?.price === 0) {
    return <div>{original?.price?.toFixed(0)} €/MWh</div>;
  }

  return null;
};
