import axiosInstance from '@utils/axiosInstance';
import fetchWrapper, { type ReqError } from '@utils/fetch';
import { all_v2_trades_path, v2_trades_path } from '@utils/routes';
import { Trade } from '@utils/types/trade';
import { TradeBody } from '@utils/types/trade_body';

export const getTrades = async (): Promise<Trade[]> => {
  const response = await fetchWrapper(all_v2_trades_path());
  const data = await response.json();

  if (!response.ok) {
    throw {
      code: data.error.code,
      message: data.error.message,
      errors: data.error.errors,
    } satisfies ReqError;
  }

  return data;
};

export const createTrade = async (trade: TradeBody) => {
  return (
    await axiosInstance(false).post<Trade>(v2_trades_path(), trade, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).data;
};
