/**
 * THIS FILE IS AUTO_GENERATED DO NOT UPDATE IT
 * RUN THE FOLLOWING COMMAND TO GENERATE IT : rails runner "./scripts/front_static/generate_price_accepted_power_exchanges_types.rb"
 */

export const IT_PRICE_AREAS = ['IT_CALA', 'IT_CNOR', 'IT_CSUD', 'IT_NORD', 'IT_SARD', 'IT_SICI', 'IT_SUD'];

export const SPOT_POWER_EXCHANGES = {
  BE: ['EPEX'],
  DE: ['EPEX'],
  ES: ['OMIE'],
  FR: ['EPEX'],
  IT: ['GME'],
  IT_CALA: ['GME'],
  IT_CNOR: ['GME'],
  IT_CSUD: ['GME'],
  IT_NORD: ['GME'],
  IT_SARD: ['GME'],
  IT_SICI: ['GME'],
  IT_SUD: ['GME'],
  NL: ['EPEX'],
  PL: ['TGE_HOURLY'],
  PT: ['OMIE'],
  UK: ['N2EX', 'EPEX', 'IMRP'],
  FI: ['N2EX'],
  IE: ['SEM'],
  IE_SEM: ['SEM'],
} as const;
