import React from 'react';
import { DropDownButton } from '@GDM/Button';
import { Filters as FilterWrapper, FilterContainer } from '@GDM/Filters';
import { EventType } from '@utils/types/event';
import { usePortfolioTable } from '../DashboardViewAll/PortfolioTable/context/usePortfolioTable';
import { usePortfolio } from '../usePortfolio';
import { AlertsFilter } from './AlertsFilters';
import { BookFilters } from './BookFilters';
import { ClientFilter } from './clientIdFilter';
import { CommissioningDatesFilter } from './CommissioningDateFilter';
import ContractTypeFilter from './ContractTypeFilter';
import { CountryFilters } from './CountryFilters';
import { DatesFilter } from './DatesFilter';
import { InstallationFilters } from './InstallationFilters';
import { MapToggle } from './MapToggle';
import PortfolioFilters from './PortfolioFilters';
import { useFilters } from './useFilters';

export const Filters = () => {
  const { infoFilters } = usePortfolioTable();
  const { data, dataType, loading } = usePortfolio();
  const { events, setEvents } = useFilters();

  const handleEventFilterChange = (checked: boolean, eventType: EventType) => {
    setEvents?.((currentEvents) => {
      if (checked) {
        return [...(currentEvents || []), eventType];
      } else {
        return currentEvents?.filter((event) => event !== eventType);
      }
    });
  };

  return (
    <FilterWrapper className="p-0">
      <FilterContainer size="datepicker">
        <DatesFilter />
      </FilterContainer>

      {dataType === 'installations' && <InstallationFilters />}
      {dataType === 'installations' && <CountryFilters />}
      {dataType === 'books' && <BookFilters />}

      {dataType === 'installations' && (
        <FilterContainer size="fit">
          <ContractTypeFilter />
        </FilterContainer>
      )}

      <FilterContainer size="fit">
        <div style={{ zIndex: 100 }} className={loading ? 'unavailable' : ''}>
          <DropDownButton label="common.metrics" icon="Filter" size="xs" position="right">
            {data && !!data.length && <PortfolioFilters />}
          </DropDownButton>
        </div>
      </FilterContainer>
      <FilterContainer size="fit">
        <AlertsFilter initialEvents={events} onEventFilterChange={handleEventFilterChange} />
      </FilterContainer>
      {infoFilters.commissioningDate && (
        <FilterContainer size="fit">
          <CommissioningDatesFilter />
        </FilterContainer>
      )}
      {infoFilters.clientId && (
        <FilterContainer size="fit">
          <ClientFilter />
        </FilterContainer>
      )}

      <FilterContainer size="fit">
        <MapToggle />
      </FilterContainer>
    </FilterWrapper>
  );
};
