import { useQuery } from '@tanstack/react-query';
import fetchWrapper from '@utils/fetch';
import {
  groups_v2_green_star_installations_path,
  v2_green_star_counter_parts_path,
  v2_green_star_installations_path,
} from '@utils/routes';
import { EnergyType } from '@utils/types/common-types';

export type GreenStarInstallation = {
  id: number;
  darwin_code: string;
  energy: EnergyType;
  grappe: string;
  holding: string;
  name: string;
  spv: string;
  status: string;
  created_at: string;
  updated_at: string;
};

type GreenStarGroups = {
  grappes: string[];
  spvs: string[];
  holdings: string[];
  contract_types: string[];
};

type GreenStarFilterFetchOptions = Partial<{
  grappe: string[];
  spv: string[];
  holding: string[];
  site_name: string[];
}>;

export const useGreenStarInstallations = (options: GreenStarFilterFetchOptions, enabled: boolean) => {
  const url = v2_green_star_installations_path(options);

  return useQuery({
    enabled,
    queryKey: ['greenstar_installations', url],
    queryFn: async (): Promise<GreenStarInstallation[]> => {
      const data = await fetchWrapper(url);
      const installations: GreenStarInstallation[] = await data.json();

      return installations.sort((a, b) => a.name.localeCompare(b.name));
    },
  });
};

export const useGreenStarGroups = (options: GreenStarFilterFetchOptions, enabled: boolean) => {
  const url = groups_v2_green_star_installations_path(options);

  return useQuery({
    enabled,
    queryKey: ['greenstar_installations_groups', url],
    queryFn: async (): Promise<GreenStarGroups> => {
      const data = await fetchWrapper(url);

      return data.json();
    },
  });
};

export const useGreenStarCounterParts = () => {
  return useQuery({
    queryKey: ['greenstar_counterparts'],
    queryFn: async () => {
      const data = await fetchWrapper(v2_green_star_counter_parts_path());

      return data.json();
    },
  });
};
