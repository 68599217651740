import React from 'react';
import { SelectInvoicesProvider } from '@context/selectInvoices.context';
import { useTabs } from '@GDM/Tabs';
import type { Contract } from '@utils/types/contract';
import { type InvoiceTab } from './invoices.constants';
import { Metrics } from './Metrics';
import CapaInvoices from './tabs/capa-invoices';
import IndexInvoices from './tabs/index-invoices';
import LoadCurveInvoices from './tabs/loadcurve-invoices';
import RecInvoices from './tabs/rec-invoices';
import YearlyRegulInvoices from './tabs/yearly-regul-invoices';

export const InvoicesTabContents = ({ direction }: { direction: Contract['direction'] }) => {
  const { currentTab } = useTabs<InvoiceTab>();

  const tabs: {
    [key in Contract['direction']]: {
      [key in InvoiceTab]?: React.ReactNode;
    };
  } = {
    sell: {
      loadcurve: <LoadCurveInvoices direction={direction} showDetailsButton={true} />,
      index: <IndexInvoices />,
      capa: <CapaInvoices />,
      rec: <RecInvoices />,
      yearly_regul: <YearlyRegulInvoices />,
      invoice_metrics: <Metrics />,
      consumption_based: (
        <LoadCurveInvoices
          invoiceType="consumption"
          direction="sell"
          tableDirection="buy"
          actionDirection="sell"
          showDetailsButton={false}
        />
      ),
    },
    buy: { power: <LoadCurveInvoices direction={direction} showDetailsButton={false} /> },
  };

  return <SelectInvoicesProvider key={currentTab}>{currentTab && tabs[direction][currentTab]}</SelectInvoicesProvider>;
};
