import React from 'react';
import { GroupedSelect } from '@GDM/forms';
import type { GroupedSelectMultiProps, GroupedSelectSingleProps } from '@GDM/forms/Select/grouped-select.types';
import {
  useController,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type UseControllerProps,
} from 'react-hook-form';

export type ControlledGroupedSelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
> = Omit<
  | GroupedSelectSingleProps<PathValue<TFieldValues, TFieldName>>
  | GroupedSelectMultiProps<PathValue<TFieldValues, TFieldName>[number]>,
  'onChange' | 'selectedOptions' | 'selectedOption' | 'value'
> &
  UseControllerProps<TFieldValues, TFieldName>;

export function ControlledGroupedSelect<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  disabled,
  isDisabled,
  components,
  ...props
}: ControlledGroupedSelectProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    disabled,
  });

  const allOptions = props.options.flatMap((group) => group.options);
  const value = props.isMulti
    ? allOptions.find((o) => field.value.includes(o.value)) || null
    : allOptions.find((o) => o.value === field.value) || null;

  return props.isMulti ? (
    <GroupedSelect
      {...props}
      isMulti
      name={field.name}
      isDisabled={disabled || isDisabled}
      onChange={(opt) => field.onChange(opt?.map((o) => o.value))}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
      value={value}
    />
  ) : (
    <GroupedSelect
      {...props}
      isMulti={false}
      name={field.name}
      isDisabled={disabled || isDisabled}
      onChange={(opt) => field.onChange(opt?.value)}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
      value={value}
    />
  );
}
