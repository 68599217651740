import { getSharingRights, updateSharingRight, type SharingRightBody } from '@api/sharingRights';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { SharingRight } from '@utils/types/unavailability';
import type { ToggleSharingRightsModal } from './SharingRightTable';

const QUERY_KEY = ['sharingRights'];

export const useSharingRights = () => {
  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: getSharingRights,
  });
};

export const useUpdateSharingRight = (toggle: ToggleSharingRightsModal) => {
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationKey: ['updateSharingRight'],
    mutationFn: ({ body, id }: { body: SharingRightBody; id: number }) => updateSharingRight(body, id),
    onSuccess: (newSharingRight) => {
      queryClient.setQueryData<SharingRight[]>(QUERY_KEY, (prevSharingRights) => {
        return prevSharingRights?.map((prevSharingRight) => {
          if (prevSharingRight.id === newSharingRight.id) return newSharingRight;

          return prevSharingRight;
        });
      });

      toggle(null);
    },
  });

  return updateMutation;
};
