import React from 'react';
import { useUser } from '@context/User.context';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { getSmartTranslation } from '@utils/getSmartTranslation';
import { isANumber } from '@utils/isANumber';
import { Locale } from '@utils/types/common-types';
import Highcharts, { SeriesPieOptions } from 'highcharts';

type PieChartProps = {
  printable?: boolean;
  title: string;
  data: SeriesPieOptions['data'];
  total: number | null;
  chartHeight?: number | string;
};

interface HackedChart extends Highcharts.Chart {
  textGroup?: Highcharts.SVGElement | null;
  customText?: Highcharts.SVGElement;
}

const refreshLoadEvent = (total_revenues: number | null, printable: boolean, locale: Locale, currency: string) =>
  function (this: HackedChart) {
    const value = isANumber(total_revenues)
      ? new Intl.NumberFormat(locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: 'currency',
          currency,
          notation: 'compact',
        }).format(total_revenues)
      : '--';

    // HACKY WAY TO CENTER THE TEXT INSIDE THE PIE CHART
    if (this['textGroup']) {
      this['textGroup'].destroy();
      this['textGroup'] = null;
    }

    this['textGroup'] = this.renderer.g('textGroup').add().toFront();

    this['customText'] = this.renderer
      .text(`${value}`, this.chartWidth / 2, this.chartHeight / 2 - (printable ? 4 : 0))
      .css({ fontSize: printable ? '0.875rem' : '1.4rem', fontWeight: '600' })
      .attr({ zIndex: 5 })
      .add(this['textGroup']);

    this['textGroup'].translate(-this['customText'].getBBox().width / 2, 4);
  };

function PieChart({ title, total, data, printable, chartHeight = 300 }: PieChartProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const { currency } = useRevenueReportingContext();

  const options: Highcharts.Options = {
    title: {
      text: getSmartTranslation(user, title),
    },
    chart: {
      height: chartHeight,
      events: {
        render: refreshLoadEvent(total, printable || false, user.locale, currency),
      },
    },
    tooltip: {
      formatter: function () {
        return (
          new Intl.NumberFormat(user.locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: currency,
            notation: 'compact',
          }).format(this.y) || '--'
        );
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      pie: {
        dataLabels: { enabled: false },
        showInLegend: true,
        cursor: 'pointer',
        allowPointSelect: true,
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
    },
  };

  const series: Highcharts.Options['series'] = [
    {
      name: t('common.revenues'),
      type: 'pie',
      innerSize: '75%',
      data,
    },
  ];

  return <Chart options={options} series={series} />;
}

export default PieChart;
