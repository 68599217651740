import React, { useDeferredValue, useEffect, useState } from 'react';
import { SingleSelectProps, MultiSelectProps } from '@GDM/forms';
import { Select } from '@GDM/forms/Select/Select';
import { Option } from '@utils/types/common-types';
import isEqual from 'lodash/isEqual';
import { FieldValues, PathValue, FieldPath, useController, UseControllerProps } from 'react-hook-form';

export type ControlledSelectProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = Omit<
  | SingleSelectProps<PathValue<TFieldValues, TFieldName>>
  | MultiSelectProps<PathValue<TFieldValues, TFieldName>[number]>,
  'onChange' | 'selectedOptions' | 'selectedOption'
> &
  UseControllerProps<TFieldValues, TFieldName> & {
    /** @deprecated **Do not use this**
     *
     * If you need an onChange call back use the regular select component
     *  alternatively you can React Hook Form watch or use React's useDefferedValue
     *  to check if the attached form field changed
     */
    afterChange?: SingleSelectProps<PathValue<TFieldValues, TFieldName>>['onChange'];
    ignoreFormUpdates?: boolean;
  };

export default function ControlledSelect<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  name,
  control,
  options,
  rules,
  shouldUnregister,
  defaultValue,
  components,
  afterChange,
  isDisabled,
  disabled,
  ignoreFormUpdates,
  ...selectProps
}: ControlledSelectProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    disabled,
  });

  // Local state is only necessary because of the "ignoreFormUpdates" prop
  const [localValue, setLocalValue] = useState<
    PathValue<TFieldValues, TFieldName> | PathValue<TFieldValues, TFieldName>[] | undefined
  >(field.value);

  const defferedIgnoreFormUpdates = useDeferredValue(ignoreFormUpdates);
  const ignoreFormUpdatesHasChanged = ignoreFormUpdates !== defferedIgnoreFormUpdates;

  useEffect(() => {
    if (!ignoreFormUpdatesHasChanged) return;
    if (isEqual(field.value, localValue)) return;

    if (ignoreFormUpdates) {
      setLocalValue(field.value);
    } else field.onChange(localValue);
  }, [field, field.value, ignoreFormUpdates, ignoreFormUpdatesHasChanged, localValue]);

  return selectProps.isMulti ? (
    <Select
      {...selectProps}
      isMulti
      name={field.name}
      options={options}
      isDisabled={disabled || isDisabled}
      onChange={(option: Option<PathValue<TFieldValues, TFieldName>>[] | null) => {
        const value = option?.map(({ value }) => value);
        setLocalValue(value);

        if (ignoreFormUpdates) return;

        field.onChange(value ?? null);
      }}
      selectedOption={undefined}
      selectedOptions={ignoreFormUpdates ? (localValue as PathValue<TFieldValues, TFieldName>[]) : field.value}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
    />
  ) : (
    <Select
      {...selectProps}
      isMulti={false}
      name={field.name}
      options={options}
      isDisabled={disabled || isDisabled}
      onChange={(option: Option<PathValue<TFieldValues, TFieldName>> | null) => {
        const value = option?.value;
        setLocalValue(value);
        if (ignoreFormUpdates) return;

        field.onChange(value);
        if (afterChange) afterChange(option);
      }}
      selectedOption={ignoreFormUpdates ? (localValue as PathValue<TFieldValues, TFieldName>) : field.value}
      selectedOptions={undefined}
      errorMessage={fieldState.error?.message}
      hasError={Boolean(fieldState.error)}
      components={components}
    />
  );
}
