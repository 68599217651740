import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import fetchWrapper from '@utils/fetch';
import { gather_meter_data_v2_dashboardviewall_index_path } from '@utils/routes';
import { portfolioContext, PortFolioContextType } from './portfolio.context';
import { RawDashboardViewAllData } from './portfolio.definitions';

export const usePortfolio = (): PortFolioContextType => useContext(portfolioContext);

export const usePortfolioQuery = (startDate: string | undefined, endDate: string | undefined) => {
  const url = gather_meter_data_v2_dashboardviewall_index_path({
    firstdate: startDate,
    lastdate: endDate,
  });

  return useQuery({
    queryKey: [url],
    queryFn: async (): Promise<{
      data: RawDashboardViewAllData[];
      books: RawDashboardViewAllData[];
      sum_realtime_portfolio: number;
      realtime_ratio: number;
    }> => {
      const response = await fetchWrapper(url);

      return response.json();
    },
  });
};
