import { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { ContractForm, ContractType } from '@utils/types/contract';
import { MarketFuture } from '@utils/types/market_future';
import dayjs from 'dayjs';
import { UseFieldArrayReturn, UseFormTrigger } from 'react-hook-form';
import { findNextCalPeriods } from '../helpers/findNextCalPeriods';
import { makeSubPeriod } from '../helpers/makeSubPeriod';

export const useDatePeriodInteraction = ({
  marketFutures,
  contractType,
  subPeriods,
  trigger,
}: {
  marketFutures: UseQueryResult<MarketFuture[]>;
  contractType: ContractType | null;
  subPeriods: UseFieldArrayReturn<ContractForm, 'contract_sub_periods_attributes', 'field_internal_id'>;
  trigger: UseFormTrigger<ContractForm>;
}) => {
  const [marketFuture, setMarketFuture] = useState<MarketFuture | null>(null);

  const fromMarketFutures = async (marketFutures: MarketFuture[]) => {
    if (!contractType) return;

    marketFutures.forEach((period) => {
      const { start_date, end_date } = period;

      const subPeriod = makeSubPeriod(contractType, {
        dates: [dayjs(start_date).toDate(), dayjs(end_date).toDate()],
        phase_type: null,
        market_future: period,
      });

      subPeriods.append(subPeriod);
    });

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new sub-period dates are validated.
    if (await trigger('contract_sub_periods_attributes')) trigger('contract_sub_periods_attributes');
  };

  const onDatePeriodClick = (period: string, index: number) => {
    const selected = marketFutures.data?.find((i) => i.name === period);

    if (selected && marketFutures) {
      setMarketFuture(selected);

      // Auto generate the next periods if the user selects a CAL period and it's the last in the list
      if (selected.time_type === 'year' && index + 1 === subPeriods?.fields.length) {
        const cals = findNextCalPeriods(selected, marketFutures.data || []);

        if (cals) fromMarketFutures(cals);
      }
    }
  };

  const preSelectedPeriod = marketFutures.data?.find(({ id }) => id === marketFuture?.id)?.name;

  return { onDatePeriodClick, preSelectedPeriod };
};
