import { ContractForm } from '@utils/types/contract';
import { ContractType } from '@utils/types/contract/unions';
import { UseFormReturn } from 'react-hook-form';
import { AdditionalInfo } from '../../Form/sections/AdditionalInfo/AdditionalInfo';
import { Cap } from '../../Form/sections/Cap/Cap';
import { ContractParams } from '../../Form/sections/ContractParams/ContractParams';
import { ContractType as ContractTypeSection } from '../../Form/sections/ContractType/ContractType';
import { GeneralInfo } from '../../Form/sections/GeneralInfo/GeneralInfo';
import { HedgeBlocks } from '../../Form/sections/HedgeBlocks/HedgeBlocks';
import { InvoiceSettings } from '../../Form/sections/InvoiceSettings/InvoiceSettings';
import { PaymentDelay } from '../../Form/sections/PaymentDelay/PaymentDelay';
import { Pricing } from '../../Form/sections/Pricing/Pricing';
import { Remit } from '../../Form/sections/Remit/Remit';
import { Scheme } from '../../Form/sections/Scheme/Scheme';
import { SubPeriods } from '../../Form/sections/SubPeriods/SubPeriods';
import { Taxes } from '../../Form/sections/Taxes/Taxes';
import { Turpe } from '../../Form/sections/Turpe/Turpe';
import { SubscriptionFixed } from '../../Form/Subscription/Fixed';
import { SubscriptionVariable } from '../../Form/Subscription/Variable';
import { ContractFormGlobals, ContractFormSection } from './types';

export const SECTIONS_NAMES = [
  'general-information',
  'contract-type',
  'payment-delay',
  'additional-info',
  'contract-params',
  'pricing',
  'prices-indexation',
  'cap',
  'taxes',
  'sub-periods',
  'hedge-blocks',
  'invoicing-settings',
  'remit',
];

export const SECTIONS_RECORD: Record<
  typeof SECTIONS_NAMES[number],
  Pick<ContractFormSection, 'title' | 'Component' | 'permissions' | 'displayPredicate'>
> = {
  'general-information': {
    title: 'contracts.steps.general_information',
    Component: GeneralInfo,
  },
  'contract-type': {
    title: 'contracts.steps.contract_type',
    Component: ContractTypeSection,
  },
  'payment-delay': {
    title: 'payment_delay.section_name',
    Component: PaymentDelay,
  },
  'additional-info': {
    title: 'contracts.steps.additional_info',
    permissions: ['display:non_engie'],
    Component: AdditionalInfo,
  },
  'contract-params': {
    title: 'contracts.steps.contract_params',
    Component: ContractParams,
  },
  pricing: {
    title: 'contracts.steps.pricing',
    Component: Pricing,
  },
  // Prices indexation is the same as pricing, but with a different title
  'prices-indexation': {
    title: 'contracts.steps.prices_indexation',
    Component: Pricing,
    displayPredicate: (form: UseFormReturn<ContractForm>) => Boolean(form.watch('scheme')),
  },
  cap: {
    title: 'contracts.steps.cap',
    Component: Cap,
  },
  taxes: {
    title: 'common.taxes',
    Component: Taxes,
    displayPredicate: (form: UseFormReturn<ContractForm>) => Boolean(form.watch('scheme')),
  },
  'sub-periods': {
    title: 'contracts.steps.sub_periods',
    Component: SubPeriods,
  },
  'hedge-blocks': {
    title: 'contracts.steps.hedge_blocks',
    Component: HedgeBlocks,
  },
  'invoicing-settings': {
    title: 'contracts.steps.invoice_settings',
    Component: InvoiceSettings,
    // Only display invoicing settings for buyers that have SY by Cegedim.
    displayPredicate: (form: UseFormReturn<ContractForm>, globals: ContractFormGlobals) => {
      const buyersThatHaveSyByCegedim = ['EDF OA'];
      const [calcBase, contractType] = form.watch(['calc_base', 'type']);

      if (!globals.buyer?.short_name) return false;

      return (
        buyersThatHaveSyByCegedim.includes(globals.buyer.short_name) &&
        (Boolean(calcBase) || contractType === 'ContractCrEdf')
      );
    },
  },
  remit: {
    title: 'common.remit',
    Component: Remit,
    // Only display REMIT section for contracts of installations whose nominal power is higher than 10MW.
    displayPredicate: (_, globals: ContractFormGlobals) => {
      if (!globals.installation) return false;

      return globals.installation.p_max > 10000;
    },
  },
  scheme: {
    title: 'contracts.steps.scheme',
    Component: Scheme,
  },
  turpe: {
    title: 'contracts.steps.turpe',
    Component: Turpe,
    displayPredicate: (form: UseFormReturn<ContractForm>) => Boolean(form.watch('scheme')),
  },
  'subscription-fixed': {
    title: 'contracts.steps.subscription_fixed',
    Component: SubscriptionFixed,
    displayPredicate: (form: UseFormReturn<ContractForm>) => Boolean(form.watch('scheme')),
  },
  'subscription-variable': {
    title: 'contracts.steps.subscription_variable',
    Component: SubscriptionVariable,
    displayPredicate: (form: UseFormReturn<ContractForm>) => Boolean(form.watch('scheme')),
  },
};

/**
 * Sections per contract type
 *
 * This object defines the sections that are displayed for each contract type
 */
export const SECTIONS_PER_CONTRACT_TYPES: Record<ContractType, (keyof typeof SECTIONS_RECORD)[]> = {
  ContractCapa: ['general-information', 'contract-type', 'pricing', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCapaProxy: ['general-information', 'contract-type', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCppa: [
    'general-information',
    'contract-type',
    'taxes',
    'sub-periods',
    'hedge-blocks',
    'payment-delay',
    'additional-info',
  ],
  ContractSupplyCppa: [
    'general-information',
    'contract-type',
    'taxes',
    'sub-periods',
    'hedge-blocks',
    'payment-delay',
    'additional-info',
  ],
  ContractCr: [
    'general-information',
    'contract-type',
    'contract-params',
    'sub-periods',
    'payment-delay',
    'additional-info',
  ],
  ContractCrEdf: [
    'general-information',
    'contract-type',
    'contract-params',
    'pricing',
    'cap',
    'invoicing-settings',
    'payment-delay',
    'additional-info',
  ],
  ContractGc: ['general-information', 'contract-type', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractGo: ['general-information', 'contract-type', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractOa: [
    'general-information',
    'contract-type',
    'contract-params',
    'pricing',
    'cap',
    'invoicing-settings',
    'payment-delay',
    'additional-info',
  ],
  ContractSoa: [
    'general-information',
    'contract-type',
    'sub-periods',
    'hedge-blocks',
    'payment-delay',
    'additional-info',
  ],
  ContractSupplyUppa: [
    'general-information',
    'contract-type',
    'sub-periods',
    'hedge-blocks',
    'payment-delay',
    'additional-info',
  ],
  ContractSwap: ['general-information', 'contract-type', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractSwapPl: [
    'general-information',
    'contract-type',
    'sub-periods',
    'pricing',
    'payment-delay',
    'additional-info',
  ],
  ContractSwapUk: [
    'general-information',
    'contract-type',
    'sub-periods',
    'pricing',
    'contract-params',
    'payment-delay',
    'additional-info',
  ],
  ContractSwapIe: [
    'general-information',
    'contract-type',
    'sub-periods',
    'pricing',
    'cap',
    'payment-delay',
    'additional-info',
  ],
  ContractSwapIt: [
    'general-information',
    'contract-type',
    'sub-periods',
    'pricing',
    'contract-params',
    'payment-delay',
    'additional-info',
  ],
  'ContractGo-LIGHT': ['general-information', 'contract-type', 'sub-periods'],
  'ContractSoa-LIGHT': ['general-information', 'contract-type', 'sub-periods'],
  ContractSupplyAuxiliaries: [
    'general-information',
    'contract-type',
    'scheme',
    'turpe',
    'subscription-fixed',
    'subscription-variable',
    'prices-indexation',
    'taxes',
  ],
};
