import { createWindmill, destroyWindmill, getWindmills, updateWindmill } from '@api/windmills';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Windmill } from '@utils/types/turbine';
import type { WindmillForm } from './Turbines';

export const useWindmills = (installationName: string) => {
  return useQuery({
    queryKey: ['windmills', installationName],
    queryFn: () => getWindmills(installationName),
  });
};

export const useWindmillsCreateMutation = (installationName: string, toggle: (windmill: Windmill | null) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['windmills', installationName],
    mutationFn: (windmill: WindmillForm) => createWindmill(installationName, windmill),
    onSuccess: (windmill) => {
      queryClient.setQueryData<Windmill[]>(['windmills', installationName], (windmills) => {
        const clonedWindmills = windmills ? [...windmills] : [];

        clonedWindmills?.push(windmill);

        toggle(null);

        return clonedWindmills;
      });
    },
  });
};

export const useWindmillsUpdateMutation = (installationName: string, toggle: (windmill: Windmill | null) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['windmills', installationName],
    mutationFn: (windmill: WindmillForm) => updateWindmill(windmill.id, windmill),
    onSuccess: (newWindmill) => {
      queryClient.setQueryData<Windmill[]>(['windmills', installationName], (windmills) => {
        const clonedWindmills = windmills ? [...windmills] : [];

        toggle(null);

        return clonedWindmills.map((windmill) => (newWindmill.id === windmill.id ? newWindmill : windmill));
      });
    },
  });
};

export const useWindmillsDestroyMutation = (installationName: string, toggle: (windmill: Windmill | null) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['windmills', installationName],
    mutationFn: (id: Windmill['id']) => destroyWindmill(id),
    onSuccess: (deletedWindmill) => {
      queryClient.setQueryData<Windmill[]>(['windmills', installationName], (windmills) => {
        const clonedWindmills = windmills ? [...windmills] : [];

        toggle(null);

        return clonedWindmills.filter((windmill) => deletedWindmill.id !== windmill.id);
      });
    },
  });
};
