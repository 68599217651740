import React from 'react';
import { Button } from '@GDM/Button';
import { tString } from '@hooks/useTranslation';
import type { RowModel } from '@tanstack/react-table';
import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import type { Locale } from '@utils/types/common-types';
import type { SharingRight } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

export const SharingRightActions = ({
  getFilteredRowModel,
  locale,
}: {
  getFilteredRowModel: () => RowModel<SharingRight>;
  locale: Locale;
}) => {
  const t = tString(locale);

  const handleDownload = () => {
    const header: HeadCsv<SharingRight>[] = [
      { label: 'common.installation', key: 'installation.name' },
      { label: 'common.country', key: 'installation.country' },
      {
        label: 'monitoring.unavailabilities.aggregator',
        key: 'market_player.long_name',
      },
      {
        label: 'monitoring.unavailabilities.sharing_permission',
        key: 'formatted_sharing_status',
        transform: (value, row) => {
          return t(`monitoring.unavailabilities.${row.formatted_sharing_status}`);
        },
      },
      { label: 'monitoring.unavailabilities.credentials', key: 'credential.username' },
      { label: 'monitoring.unavailabilities.external_ref', key: 'reference' },
    ];
    const data = getFilteredRowModel().rows.map((row) => row.original);
    const csv = jsonToCsv(data, header);
    const fileName = `Streem_Unavailability_Sharing_Rights_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`;

    fileDownload(csv, fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <div className="d-flex justify-content-end mb-3">
      <Button size="xxs" variant="primary-2" text="common.download" icon="Download" onClick={handleDownload} />
    </div>
  );
};
