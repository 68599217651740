import React from 'react';
import { DateCell, HeadCell, NoWrapHeadCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import {
  ActionsCell,
  CheckboxCell,
  ContractMeterNameCell,
  CounterpartCell,
  CountryCell,
  HorizonCell,
  PMaxCell,
  PricingCell,
  SellerCell,
  TypeCell,
  ProductTypeCell,
} from './Cells';
import { ClassificationCell } from './Cells/ClassificationCell';
import { VolumeCell } from './Cells/VolumeCell';

export const CONTRACT_COLUMNS = {
  CHECKBOX: {
    id: 'selector',
    cell: CheckboxCell,
  },

  TYPE: {
    accessorKey: 'type',
    header: () => <HeadCell label="common.contract" />,
    cell: (props) => <TypeCell contract={props.row.original} />,
  },

  PRODUCT_TYPE: {
    accessorKey: 'product_type',
    header: () => <HeadCell label="contracts.product_type.product_type" />,
    cell: (props) => <ProductTypeCell {...props} />,
  },

  VOLUME: {
    id: 'volume',
    header: () => <HeadCell label="sales_management.volume" />,
    cell: (props) => <VolumeCell contract={props.row.original} />,
  },

  CLASSIFICATION: {
    id: 'classification',
    header: () => <HeadCell label="sales_management.classification" />,
    cell: (props) => <ClassificationCell contractType={props.row.original.type} />,
  },

  COUNTRY: {
    header: () => <HeadCell label="common.country" />,
    accessorFn: (contract) => contract.installation.country,
    cell: CountryCell,
    id: 'installation.country',
  },

  INSTALLATION: {
    header: () => <HeadCell label="common.installation" />,
    cell: ContractMeterNameCell,
    id: 'installation.name',
    accessorFn: (a) => a.installation.name,
  },

  COMMISSIONING_DATE: {
    header: () => <HeadCell label="sales_management.commissioning_date" />,
    accessorFn: (i) => i.installation.commissioning_date,
    id: 'commissioning_date',
    cell: ({ getValue }) => <DateCell getValue={getValue} format="YYYY-MM-DD" />,
  },

  CARD_I: {
    header: () => <NoWrapHeadCell label="sales_management.card_i" />,
    accessorKey: 'card_i',
  },

  P_MAX: {
    header: () => <HeadCell label="common.capacity" />,
    cell: PMaxCell,
    accessorKey: 'p_max',
  },

  SELLER: {
    header: () => <HeadCell label="sales_management.seller" />,
    cell: SellerCell,
    accessorFn: (a) => a.seller?.short_name,
    id: 'seller.short_name',
  },

  SELLER_SIREN: {
    header: () => <HeadCell label="sales_management.seller_siren" />,
    accessorFn: (i) => i.seller?.company_number,
    id: 'seller_siren',
    enableSorting: false,
  },

  BUYER: {
    header: () => <HeadCell label="sales_management.buyer" />,
    cell: CounterpartCell,
    accessorFn: (a) => a.buyer?.short_name,
    id: 'buyer.short_name',
  },

  HORIZON: {
    header: () => <HeadCell label="common.horizon" />,
    cell: HorizonCell,
    accessorKey: 'start_date',
  },

  PRICING: {
    header: () => <HeadCell label="common.pricing" />,
    cell: PricingCell,
    enableSorting: false,
    id: 'pricing',
  },

  ACTIONS: {
    id: 'actions',
    cell: ActionsCell,
  },
} as const satisfies Record<string, ColumnDef<Contract>>;
