import fetchWrapper, { type ReqError } from '@utils/fetch';
import { v2_sellers_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const getSellers = async (): Promise<MarketPlayer[]> => {
  const response = await fetchWrapper(v2_sellers_path());
  const data = await response.json();

  if (!response.ok) {
    throw {
      code: data.error.code,
      message: data.error.message,
      errors: data.error.errors,
    } satisfies ReqError;
  }

  return data;
};
