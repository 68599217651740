import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import compact from 'lodash/compact';
import intersection from 'lodash/intersection';
import { ExportFormData, MeasureType } from 'pages/Export/export.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const PRICE_TYPE_COUNTRIES: Record<string, CountryCode[]> = {
  epex: ['FR', 'ES', 'PL', 'UK', 'FI'],
  nordpool: ['FR', 'UK'],
  impr: ['UK'],
  gme: ['IT'],
};

export const MeasureTypesSelect = ({ installations }: { installations: InstallationWithMeterInfo[] }) => {
  const user = useUser();
  const isForecast = user.roles?.includes('viewer_forecast');
  const { control, getValues } = useFormContext<ExportFormData>();

  const sample = useWatch({ control, name: 'sample' });

  const installationNames = new Set([
    ...useWatch({ control, name: 'meterNames' }),
    ...useWatch({ control, name: 'books' }).flatMap((book) => book.installation_names),
  ]);

  const selectedCountries = [
    ...new Set(
      installations
        .filter((installation) => installationNames.has(installation.name))
        .map((installation) => installation.country),
    ),
  ];

  const isFr = selectedCountries.includes('FR');
  const isEs = selectedCountries.includes('ES');
  const isPl = selectedCountries.includes('PL');
  const isIt = selectedCountries.includes('IT');
  const isIe = selectedCountries.includes('IE');

  const showImbalance = isFr || isEs;
  const showVoltage = isFr;

  const showEpex = intersection(PRICE_TYPE_COUNTRIES.epex, selectedCountries).length > 0;
  const showNordpool = intersection(PRICE_TYPE_COUNTRIES.nordpool, selectedCountries).length > 0;
  const showImpr = intersection(PRICE_TYPE_COUNTRIES.impr, selectedCountries).length > 0;

  const measuresOptions: Option<MeasureType>[] = compact([
    { label: 'monitoring.export.active', value: 'active' },
    { label: 'monitoring.export.corrected', value: 'corrected' },
    { label: 'monitoring.export.reactive_positive', value: 'reactive_positive' },
    { label: 'monitoring.export.reactive_negative', value: 'reactive_negative' },
    showVoltage && { label: 'monitoring.export.tension', value: 'tension' },
    { label: 'monitoring.export.consumption', value: 'consumption' },
    isForecast && { label: 'monitoring.export.forecast', value: 'forecast' },
    isForecast && { label: 'monitoring.export.dispatch', value: 'dispatch' },

    // price options
    showEpex && { label: 'monitoring.export.spot_epex', value: 'spot_epex' },
    showNordpool && { label: 'monitoring.export.spot_nordpool', value: 'spot_nordpool' },
    showImpr && { label: 'monitoring.export.spot_imrp', value: 'spot_imrp' },

    // italian based prices
    isIt && { label: 'monitoring.export.spot_pun', value: 'spot_gme' },
    isIt && { label: 'monitoring.export.spot_gme_it-cnor', value: 'spot_gme_it-cnor' },
    isIt && { label: 'monitoring.export.spot_gme_it-cala', value: 'spot_gme_it-cala' },
    isIt && { label: 'monitoring.export.spot_gme_it-csud', value: 'spot_gme_it-csud' },
    isIt && { label: 'monitoring.export.spot_gme_it-nord', value: 'spot_gme_it-nord' },
    isIt && { label: 'monitoring.export.spot_gme_it-sard', value: 'spot_gme_it-sard' },
    isIt && { label: 'monitoring.export.spot_gme_it-sici', value: 'spot_gme_it-sici' },
    isIt && { label: 'monitoring.export.spot_gme_it-sud', value: 'spot_gme_it-sud' },

    isIe && { label: 'monitoring.export.spot_sem', value: 'spot_sem' },

    isPl && { label: 'monitoring.export.tge_hourly', value: 'tge_hourly' },

    showImbalance && { label: 'monitoring.export.positive_imbalance', value: 'positive_imbalance' },
    showImbalance && { label: 'monitoring.export.negative_imbalance', value: 'negative_imbalance' },
  ]);

  const validate = useMemo(
    () => ({
      priceCompatible: (values: MeasureType[]) => {
        const sample = getValues('sample');

        const isSpot = values.includes('spot');
        const isImbalance = values.includes('positive_imbalance') || values.includes('negative_imbalance');
        const isMixed = isSpot && isImbalance;

        if ((isSpot || isMixed) && sample === 'daily') {
          return isMixed ? 'monitoring.export.errors.mixed_price_error' : 'monitoring.export.errors.spot_price_error';
        }

        if (isImbalance && (sample === 'hourly' || sample === 'daily')) {
          return 'monitoring.export.errors.imbalance_price_error';
        }

        return true;
      },
    }),
    [getValues],
  );

  return (
    <Controller
      name="measureTypes"
      control={control}
      rules={{ required: true, validate }}
      render={({ field, fieldState }) => (
        <>
          <Select
            id="measureTypes"
            options={measuresOptions}
            label="monitoring.export.mesure_type"
            isMulti
            selectedOptions={field.value}
            onChange={(options) => field.onChange(options?.map((option) => option.value))}
            hasError={fieldState.error?.type === 'priceCompatible'}
            isOptionDisabled={(option) => {
              if (
                (sample === 'daily' &&
                  [
                    'spot_epex',
                    'spot_gme_it-cnor',
                    'spot_gme_it-cala',
                    'spot_gme_it-csud',
                    'spot_gme_it-nord',
                    'spot_gme_it-sard',
                    'spot_gme_it-sici',
                    'spot_gme_it-sud',
                    'spot_gme',
                    'spot_nordpool',
                    'spot_imrp',
                    'positive_imbalance',
                    'negative_imbalance',
                  ].includes(option.value)) ||
                (sample === 'hourly' && ['positive_imbalance', 'negative_imbalance'].includes(option.value))
              ) {
                return true;
              }

              return false;
            }}
            classNamePrefix="export-measure-types"
          />
          {fieldState.error?.type === 'priceCompatible' && (
            <Text type="danger" text={fieldState.error?.message || 'errors.unknown_error'} />
          )}
        </>
      )}
    />
  );
};
