import React from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer } from '@GDM/Filters/FilterContainer';
import { Filters } from '@GDM/Filters/Filters';
import { useInstallations } from '@hooks/requests/useInstallations';
import { EnergyType, Option } from '@utils/types/common-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import styles from './styles.module.scss';
import { RiskAnalysisFormFilters } from './types';

const DEFAULT_PERIOD = [dayjs().subtract(1, 'year').toDate(), dayjs().add(5, 'year').toDate()];
const MAX_DATE = dayjs().add(5, 'months').toDate();

export const RiskAnalysisConfiguration = ({
  form: { control },
  setFilterReadiness,
}: {
  form: UseFormReturn<RiskAnalysisFormFilters>;
  setFilterReadiness: (isReady: boolean) => void;
}) => {
  const { data: installations } = useInstallations({
    type: 'operational',
    includeInactive: false,
    withMeterInfo: false,
  });

  const installationsOptions =
    installations?.map((installation) => ({
      value: installation.name,
      label: installation.name,
    })) || [];

  const uniqueBookNames = Array.from(
    new Set(installations?.flatMap((installation) => installation.books?.map((book) => book.name))),
  );

  const bookOptions =
    uniqueBookNames?.map((book) => ({
      value: book,
      label: book,
    })) || [];

  const energyOptions =
    installations?.reduce((acc, installation) => {
      if (!acc.find((energy) => energy.value === installation.energy)) {
        return [
          ...acc,
          {
            value: installation.energy,
            label: `energy.${installation.energy}`,
          },
        ];
      }

      return acc;
    }, [] as Option<EnergyType>[]) || [];

  const contractTypesOptions = [
    {
      value: 'feed_in_tariff',
      label: 'feed_in_tariff',
    },
    {
      value: 'feed_in_premium',
      label: 'feed_in_premium',
    },
    {
      value: 'merchant',
      label: 'merchant',
    },
    {
      value: 'no_contract',
      label: 'no_contract',
    },
  ];

  return (
    <Filters className={classNames('py-4', styles['configuration-filters'])}>
      <FilterContainer size="fit">
        <ControlledDatePicker
          name="period"
          control={control}
          selectsRange
          className={styles.datepicker}
          defaultValue={DEFAULT_PERIOD}
          maxDate={MAX_DATE}
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="holding"
          control={control}
          options={bookOptions}
          className={styles.select}
          placeholder="common.book"
        />
      </FilterContainer>

      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="installations"
          control={control}
          options={installationsOptions}
          className={styles.select}
          placeholder="sales_management.installation"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="energy"
          control={control}
          options={energyOptions}
          className={styles.select}
          placeholder="common.technology"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="contract_type"
          control={control}
          options={contractTypesOptions}
          placeholder="common.filters.contract_type"
          className={styles.select}
        />
      </FilterContainer>
    </Filters>
  );
};
