import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import type { Contract } from '@utils/types/contract';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const LoadCurveInvoices = ({
  direction,
  requestDirection,
  tableDirection,
  actionDirection,
  showDetailsButton,
  invoiceType = 'load_curve',
}: {
  direction: Contract['direction'];
  requestDirection?: Contract['direction'];
  tableDirection?: Contract['direction'];
  actionDirection?: Contract['direction'];
  showDetailsButton: boolean;
  invoiceType?: MeterInvoiceSource;
}) => {
  const [date, setDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
  const req = useInvoicesByType(invoiceType, requestDirection || direction, date);

  const handleDateChange = (date: Date | null) => {
    setDate(date ?? dayjs().subtract(1, 'month').toDate());
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="load_curve"
      isLoading={req.isPending}
      startDate={date}
      onDateChange={handleDateChange}
      isDateRange={false}
      direction={tableDirection || direction}
      showDetailsButton={showDetailsButton}
      actionDirection={actionDirection || direction}
    />
  );
};

export default LoadCurveInvoices;
