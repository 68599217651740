import React, { useDeferredValue, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { Chart } from '@GDM/Chart/Chart';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { v2_green_star_risks_path, v2_streem_valuations_path } from '@utils/routes';
import debounce from 'lodash/debounce';
import { Control, UseFormReturn } from 'react-hook-form';
import {
  FOCUS_OPTIONS,
  VALUATION_METRICS_PER_TYPE,
  SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS,
  VALUATION_METRICS_FORM_NAMES_TO_LABELS,
  SENSITIVITY_METRICS_PER_TYPE,
} from '../constants';
import styles from '../styles.module.scss';
import { RiskAnalysisReportType, RiskReportData, RiskAnalysisFormFilters } from '../types';
import { useRiskAnalysisTable } from './hooks/table';
import { useRiskAnalysisCharts } from './hooks/useCharts';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReport = ({
  type,
  areFiltersReady,
  form: { setValue, watch, control },
}: {
  areFiltersReady: boolean;
  type: RiskAnalysisReportType;
  form: UseFormReturn<RiskAnalysisFormFilters>;
}) => {
  const display = watch('display');
  const splitBy = watch('splitBy');
  const splitByMetric = splitBy === 'split_by_metric';
  const metricGroup = watch('metricGroup');

  const defferedeMetricGroup = useDeferredValue(metricGroup);

  useEffect(() => {
    if (metricGroup === defferedeMetricGroup) return;

    if (['price', 'mtom'].includes(metricGroup) && splitBy === 'split_by_focus') {
      setValue('splitBy', 'split_by_metric');
    }

    if (metricGroup === 'all' && display === 'chart') {
      setValue('display', 'table');
    }
  }, [metricGroup, defferedeMetricGroup, splitBy, display, setValue]);

  const isReportNested = type === 'sensitivity';

  const { primaryFocus, granularity, grappe, holding, spv, installations, energy, contract_type, period } = watch();

  const generatedRoute = type === 'valuation' ? v2_streem_valuations_path : v2_green_star_risks_path;

  const options = {
    primaryFocus,
    granularity,
    grappe,
    holding,
    spv,
    energy,
    contract_type,
    start_date: period[0],
    end_date: period[1],
    site_name: installations,
  };

  const url = generatedRoute(options);
  const exportUrl = generatedRoute({ ...options, format: 'xls' });

  const reportQuery = useQuery({
    queryKey: [url],
    enabled: areFiltersReady,
    queryFn: async (): Promise<RiskReportData> => {
      const response = await fetch(url);

      return response.json();
    },
  });

  const [lastValidData, setLastValidData] = useState(reportQuery.data);

  if (reportQuery.data && reportQuery.data !== lastValidData) {
    setLastValidData(reportQuery.data);
  }

  const data = areFiltersReady ? reportQuery.data : lastValidData;

  return (
    <div>
      {type === 'sensitivity' && <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />}
      {type === 'valuation' && <RiskAnalysisValuationReportHeader control={control} exportUrl={exportUrl} />}
      {data && (
        <RiskAnalysisReportVisualisation
          data={data}
          isReportNested={isReportNested}
          isSplitByMetric={splitByMetric}
          type={type}
          metricGroup={metricGroup}
          display={display}
        />
      )}
    </div>
  );
};

const RiskAnalysisReportVisualisation = ({
  data: rawData,
  isReportNested,
  isSplitByMetric,
  type,
  metricGroup,
  display,
}: {
  data: RiskReportData;
  isReportNested: boolean;
  isSplitByMetric: boolean;
  type: RiskAnalysisReportType;
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
  display: RiskAnalysisFormFilters['display'];
}) => {
  const [expanded, setExpanded] = useState({});
  const data = useFormatReportData(rawData, isReportNested, isSplitByMetric);
  const { columns, rows } = useRiskAnalysisTable(data, isReportNested);

  const metricsToLabel =
    type === 'valuation' ? VALUATION_METRICS_FORM_NAMES_TO_LABELS : SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS;

  const metricsPerType = type === 'valuation' ? VALUATION_METRICS_PER_TYPE : SENSITIVITY_METRICS_PER_TYPE;

  const columnFilters = useMemo<[{ id: string; value: string[] }] | []>(() => {
    if (metricGroup === 'all') {
      return [];
    } else {
      const blackList = Object.entries(metricsPerType).reduce<string[]>(
        (list, [metricType, metrics]) => (metricType === metricGroup ? list : [...list, ...metrics]),
        [],
      );

      return [
        {
          id: 'name',
          value: blackList.map((metric) => {
            return metricsToLabel[metric as keyof typeof metricsToLabel];
          }),
        },
      ];
    }
  }, [metricGroup, metricsPerType, metricsToLabel]);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    state: {
      expanded,
      columnFilters,
    },
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useLayoutEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  const charts = useRiskAnalysisCharts({
    data,
    isReportNested,
    splitByMetric: isSplitByMetric,
    metricsBlackList: columnFilters?.[0]?.value || [],
  });

  return (
    <div className="p-4">
      {display === 'table' && (
        <Table>
          <TableHead table={table} />
          <TableBody table={table} />
        </Table>
      )}
      {display === 'chart' && (
        <div className={styles['chart-grid']}>
          {charts.map((chart) => (
            <Chart {...chart} key={chart.key} />
          ))}
        </div>
      )}
    </div>
  );
};

const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
}) => {
  return (
    <div className="p-4">
      <div className="d-flex gap-4">
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS}
          placeholder="common.focus"
          className={styles.select}
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex gap-4">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.volume', value: 'volume' },
              { label: 'risk_analysis.price', value: 'price' },
              { label: 'risk_analysis.m_to_m', value: 'mtom' },
              { label: 'risk_analysis.all', value: 'all' },
            ]}
          />
          <ControlledRadioButtons
            name="splitBy"
            control={control}
            options={[
              { label: 'risk_analysis.split_by_focus', value: 'split_by_focus' },
              { label: 'risk_analysis.split_by_metric', value: 'split_by_metric' },
            ]}
          />
          <ControlledRadioButtons
            name="granularity"
            control={control}
            options={[
              { label: 'risk_analysis.weekly', value: 'weekly' },
              { label: 'risk_analysis.monthly', value: 'monthly' },
            ]}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
          />
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};

const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
}) => {
  return (
    <div className="p-4">
      <div>
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS}
          placeholder="common.focus"
          className={styles.select}
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.all', value: 'all' },
              { label: 'Price Outright', value: 'price_outright' },
              { label: 'Profile Risk', value: 'profile_risk' },
              { label: 'Balancing Risk', value: 'balancing_risk' },
            ]}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
            defaultValue="table"
          />

          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};
