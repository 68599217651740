import React, { useEffect, useMemo } from 'react';
import { useUser } from '@context/User.context';
import { DropDownButton } from '@GDM/Button/DropDownButton';
import { Checkbox, sectionStyles } from '@GDM/forms';
import {
  getDefaultValue,
  useContractPartiesOptions,
} from '@pages/Contracts/Contract/Form/sections/GeneralInfo/hooks/useContractPartiesOptions';
import { useConfigurableFields } from '@pages/Contracts/Contract/hooks/useConfigurableFields';
import dayjs from 'dayjs';
import { useFormState } from 'react-hook-form';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { DateInput } from '../../components/Inputs/Date';
import { SectionHeader } from '../../components/SectionHeader';
import { getDefaultDirection } from '../../helpers/getDefaultDirection';
import { ConfigurableFields } from './components/ConfigurableFields';
import { MainContractInfo } from './components/MainContractInfo';

export const GeneralInfo = ({ title, readOnly, globals, queries, form }: ContractFormSectionProps) => {
  const { installation, offtaker, opex } = globals;
  const { control, watch, setValue, trigger } = form;
  const { account_type } = useUser();
  const [contractType, invoicingPlatform] = watch(['type', 'invoicing_platform']);
  const direction = watch('direction', getDefaultDirection(account_type, opex));
  const { dirtyFields } = useFormState({ control });

  const { myEntitiesOptions, counterpartsOptions } = useContractPartiesOptions(
    account_type,
    queries.marketPlayers.data || [],
    queries.linkedMarketPlayers.data || [],
    queries.intermediaries.data || [],
    installation || null,
  );

  useEffect(() => {
    /**
     * This logic exists top check if the one of the following fields is dirty (has been changed by the user)
     * If so, it will set the default values for the buyer_id and seller_id fields
     * Otherwise, it will leave them as they are (like in edit mode)
     */
    const fieldNames: Array<keyof typeof dirtyFields> = ['my_entity_id', 'counterpart_id', 'installation_uuid'];
    const isOneFieldDirty = fieldNames.some((fieldName) => dirtyFields[fieldName]);

    if (myEntitiesOptions && counterpartsOptions && installation && isOneFieldDirty) {
      setValue('my_entity_id', getDefaultValue(myEntitiesOptions, offtaker || null, installation));
      setValue('counterpart_id', getDefaultValue(counterpartsOptions, offtaker || null, installation));
    }
  }, [setValue, myEntitiesOptions, counterpartsOptions, offtaker, installation, dirtyFields, direction]);

  const [configurableFields, setConfigurableFields] = useConfigurableFields<ConfigurableField>('configurableFields');

  useEffect(() => {
    if (contractType === 'ContractSwapPl') setValue('currency', 'PLN');
    if (contractType === 'ContractSwapUk') setValue('currency', 'GBP');
  }, [setValue, contractType]);

  useEffect(() => {
    if (invoicingPlatform === 'cegedim') trigger('contract_nb');
  }, [invoicingPlatform, trigger]);

  const defaultStartDate = useMemo(() => new Date(), []);
  const defaultEndDate = dayjs(defaultStartDate).add(1, 'year').toDate();

  return (
    <FormSectionLayout
      title={title}
      headerActions={
        <SectionHeader>
          <DropDownButton
            tooltip="sales_management.configurable_fields.tooltip"
            className="ml-2"
            icon="Settings"
            variant="primary-2"
            position="right"
            data-cy="configurable-fields-dropdown"
            noChevron
            floating
          >
            <div className="p-2 d-flex flex-column gap-1" data-cy="configurable-fields-list">
              {CONFIGURABLE_FIELDS.map((field) => (
                <Checkbox
                  label={`sales_management.configurable_fields.${field}`}
                  checked={configurableFields.has(field)}
                  onChange={() => {
                    setConfigurableFields((prev) => {
                      const next = new Set(prev);

                      if (prev.has(field)) {
                        next.delete(field);
                      } else {
                        next.add(field);
                      }

                      return next;
                    });
                  }}
                  key={field}
                  name={`configurable-field-${field}`}
                  data-cy={`configurable-field-${field}`}
                />
              ))}
            </div>
          </DropDownButton>
        </SectionHeader>
      }
      body={
        <div className={sectionStyles.container} data-cy="general-information">
          <div className="p-3">
            <MainContractInfo
              control={control}
              globals={globals}
              invoicingPlatform={invoicingPlatform}
              readOnly={readOnly}
              queries={queries}
              installation={installation}
              contractType={contractType}
              myEntitiesOptions={myEntitiesOptions}
              counterpartsOptions={counterpartsOptions}
              direction={direction}
              form={form}
            />

            <div className={sectionStyles.row}>
              <DateInput
                id="StartDatePicker"
                name="start_date"
                control={control}
                rules={{ required: true }}
                label="common.start_date"
                readOnly={readOnly}
                data-cy="contract-start-date"
                defaultValue={defaultStartDate}
              />
              <DateInput
                id="EndDatePicker"
                name="end_date"
                control={control}
                rules={{ required: true }}
                label="common.end_date"
                readOnly={readOnly}
                data-cy="contract-end-date"
                defaultValue={defaultEndDate}
              />
            </div>

            <ConfigurableFields
              control={control}
              readOnly={readOnly}
              installation={installation}
              configurableFields={configurableFields}
            />
          </div>
        </div>
      }
    />
  );
};

const CONFIGURABLE_FIELDS = [
  'erp_code',
  'card_i',
  '_non_contract_props.installation.owner_erp_code',
  '_non_contract_props.installation.external_ref',
  'p_max',
  'signature_date',
] as const;

export type ConfigurableField = typeof CONFIGURABLE_FIELDS[number];
