import React, { useMemo } from 'react';
import { Trades } from '@pages/Trades/Trades/Trades';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { User } from '@utils/types/user';

interface TradesWithQueryProps {
  user: User;
}

export const TradesWithQuery = ({ user }: TradesWithQueryProps) => {
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Trades user={user} />
    </QueryClientProvider>
  );
};

export default TradesWithQuery;
