import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export const Facility = ({ row: { original } }: CellContext<Trade, unknown>) => {
  switch (original?.facility_type) {
    case 'User':
      return <div>Complet</div>;
    case 'Installation':
      return (
        <a href={`/v2/dashboard_meter/?meter=${encodeURIComponent(original.facility_name)}`}>
          {original.facility_name}
        </a>
      );
    case 'Book':
      return (
        <a href={`/v2/dashboard_meter/?book=${encodeURIComponent(original.facility_name)}`}>{original.facility_name}</a>
      );
    default:
      return null;
  }
};
