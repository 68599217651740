import React, { useMemo } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Badge, type BadgeProps } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { arrayFilter, arrayFilterMulti, arrayFilterMultiBook } from '@GDM/Table/utils/filterFns';
import type { ColumnDef } from '@tanstack/react-table';
import type Book from '@utils/types/book';
import type { SharingRight } from '@utils/types/unavailability';
import type { ToggleSharingRightsModal } from './SharingRightTable';

export const useColumns = (toggle: ToggleSharingRightsModal) => {
  return useMemo(() => {
    const columns: ColumnDef<SharingRight>[] = [
      {
        accessorKey: 'installation.name',
        id: 'installation',
        header: 'common.installation',
        filterFn: arrayFilterMulti,
        cell: (props) => (
          <MeterNameCell
            installation_name={props.getValue<string>()}
            installation_energy={props.row.original.installation?.energy}
          />
        ),
      },
      { id: 'country', accessorFn: (row) => row.installation?.country, filterFn: arrayFilterMulti },
      {
        id: 'book',
        accessorFn: (row) => row.installation?.books,
        filterFn: arrayFilterMultiBook,
        cell: (row) =>
          row
            .getValue<Book[]>()
            .map((book) => book.name)
            .join(', '),
      },
      {
        id: 'aggregator',
        accessorFn: (row) => row.market_player?.long_name,
        header: 'monitoring.unavailabilities.aggregator',
        filterFn: arrayFilterMulti,
        cell: (props) => {
          const aggregator = props.getValue<string>();

          if (!aggregator) {
            return null;
          }

          return (
            <div className="d-flex gap-2 align-items-center">
              <Icon name="ArrowRightCircle" size={14} />
              {aggregator}
            </div>
          );
        },
      },
      {
        accessorKey: 'formatted_sharing_status',
        header: 'monitoring.unavailabilities.sharing_permission',
        filterFn: arrayFilter,
        cell: (props) => {
          const value = props.getValue<SharingRight['formatted_sharing_status']>();

          const map: { [key in SharingRight['formatted_sharing_status']]: Pick<BadgeProps, 'variant' | 'label'> } = {
            unshared: { variant: 'danger', label: 'monitoring.unavailabilities.unshared' },
            shared: { variant: 'primary-1', label: 'monitoring.unavailabilities.shared' },
            not_available: { variant: 'default', label: 'monitoring.unavailabilities.not_available' },
          };

          return <Badge variant={map[value].variant} label={map[value].label} />;
        },
      },
      {
        id: 'credential',
        accessorFn: (row) => row.credential?.username,
        header: 'monitoring.unavailabilities.credentials',
      },
      {
        accessorKey: 'reference',
        header: 'monitoring.unavailabilities.external_ref',
      },
      {
        id: 'actions',
        header: '',
        cell: (props) => <Button floating icon="Edit3" onClick={() => toggle(props.row.original.id)} />,
      },
    ];

    return columns;
  }, [toggle]);
};
