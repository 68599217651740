import React from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import { Restricted } from '@components/Restricted';
import { SidebarMenu, UserMenu, SidebarMenuItem } from '@components/Sidebar';
import { SidebarProvider } from '@components/Sidebar/context';
import { AppLogo } from '@GDM/AppLogo';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useMenuData from '@hooks/useMenuData';
import { doc_index_path } from '@utils/routes';
import type { User } from '@utils/types/user';
import classNames from 'classnames';
import styles from './sidebar.module.scss';

export const Sidebar = ({ user, user_masquerade }: { user: User; user_masquerade: boolean }) => {
  const menuData = useMenuData(user);
  const activeLink = window.location.pathname;

  const { value } = useLocalStorage('STREEM_SKINS');

  return (
    <UserProvider user={user}>
      <SidebarProvider masquerading={user_masquerade} isSuperUser={Boolean(user.admin)}>
        <nav className={styles['sidebar']}>
          <div
            className={classNames(styles['sidebar-header'], {
              [styles['is-summer']]: value === 'summer',
              [styles['is-halloween']]: value === 'halloween',
              [styles['is-xmas']]: value === 'xmas',
            })}
          >
            <a href="/v2/dashboardviewall">
              <span
                className={classNames(styles['logo-wrapper'], {
                  [styles['is-pride']]: value === 'pride',
                  [styles['is-xmas']]: value === 'xmas',
                  [styles['is-halloween']]: value === 'halloween',
                })}
              >
                <AppLogo className={classNames(styles['sidebar-logo'])} />
              </span>
            </a>
          </div>

          <div className={styles['sidebar-menu']}>
            {(user_masquerade || (!user.admin && !user_masquerade)) &&
              menuData.map(
                (category) =>
                  !category.hidden && (
                    <Restricted key={category.id} permissions={category.permissions}>
                      <SidebarMenu categoryName={category.label} icon={category.icon}>
                        {category.items.map((menuItem) => (
                          <SidebarMenuItem key={menuItem.link} activeLink={activeLink} {...menuItem} />
                        ))}
                      </SidebarMenu>
                    </Restricted>
                  ),
              )}

            {user.admin && (
              <SidebarMenu categoryName="sidebar.super_admin.title" icon="Smile">
                <SidebarMenuItem
                  key="masquerade"
                  isActive={activeLink === '/v2/use_sub_client/'}
                  link="/users/masquerade/back"
                  label="use_sub_client.title"
                />
                <SidebarMenuItem
                  key="docs"
                  activeLink={activeLink}
                  link={doc_index_path()}
                  label="sidebar.admin_settings.api_doc"
                />
                <Restricted permissions={['display:subscription']}>
                  <SidebarMenuItem
                    key="subscriptions"
                    isActive={activeLink.includes('internal')}
                    link="/internal"
                    label="sidebar.super_admin.subscriptions"
                  />
                </Restricted>
              </SidebarMenu>
            )}
          </div>

          <UserMenu />
        </nav>
      </SidebarProvider>
    </UserProvider>
  );
};
