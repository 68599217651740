import React from 'react';
import { InvoiceTableDataWrapper } from '@components/invoices/InvoiceTableDataWrapper';
import { useInvoicesByIdentifier } from '@hooks/requests/meter-invoices/useInvoicesByIdentifier';
import MarketPlayer from '@utils/types/market_player';
import dayjs from 'dayjs';

export const InvoicesTab = ({ marketPlayer }: { marketPlayer: MarketPlayer }) => {
  const { data: invoices, loading } = useInvoicesByIdentifier({
    identifier: marketPlayer.id,
    source_type: 'market_player',
    endDate: dayjs().startOf('day').toDate(),
    direction: 'all',
  });

  return (
    <InvoiceTableDataWrapper
      isLoading={loading}
      invoices={invoices}
      showDetailsButton={true}
      sortByDefault="end_date"
      isLight
      direction="all"
    />
  );
};
