export const recordToFormData = (record: Record<string, unknown>) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(record)) {
    if (value instanceof Blob) {
      formData.append(key, value);
    } else {
      formData.append(key, (value || '') as string);
    }
  }

  return formData;
};
