import React, { useEffect, useState } from 'react';
import { Button } from '@GDM/Button';
import { Checkbox, Input, Select } from '@GDM/forms';
import { Row, Col } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import useAxiosDeprecated from '@hooks/useAxios';
import { DataLogger } from '@utils/types/datalogger';
import { Ftp } from '@utils/types/ftp';
import { useForm, Controller } from 'react-hook-form';
import { useInstallationsPageContext } from '../../context/installations-page.context';

const CREATE_NEW_FTP_ID = 'create_new_ftp';

type DataLoggerModalProps = {
  visible: boolean;
  toggle?: () => void;
  dataLogger?: DataLogger;
};

export const DataLoggerModal = ({ visible, toggle, dataLogger }: DataLoggerModalProps) => {
  // States
  const [ftps, setFtps] = useState<Ftp[]>([]);
  const isEdit = !!dataLogger;
  const { installations } = useInstallationsPageContext();

  // Axios
  const [request, execRequest] = useAxiosDeprecated({
    url: isEdit ? `/data_loggers/${dataLogger?.ref}` : '/data_loggers',
    method: isEdit ? 'PUT' : 'POST',
  });
  const [postFtpRequest, execPostFtpRequest] = useAxiosDeprecated({});
  const [getFtpsRequest, execGetFtpsRequest] = useAxiosDeprecated({ url: '/ftps', method: 'GET' });

  // Form
  const { handleSubmit, control, setValue, watch, reset } = useForm<DataLogger & { type: string }>();

  useEffect(() => {
    if (visible) execGetFtpsRequest();
    if (!visible) reset();
  }, [visible]);

  useEffect(() => {
    if (request.loading === 'LOADED') toggle?.();
  }, [request]);

  useEffect(() => {
    if (getFtpsRequest.loading === 'LOADED') setFtps(getFtpsRequest.data);
  }, [getFtpsRequest]);

  useEffect(() => {
    if (postFtpRequest.loading === 'LOADED') {
      if (postFtpRequest?.data?.id) setValue('ftp.id', postFtpRequest.data.id);
      execGetFtpsRequest();
    }
  }, [postFtpRequest]);

  useEffect(() => {
    if (dataLogger != undefined) {
      setValue('name', dataLogger['name']);
      setValue('identifier', dataLogger['identifier']);
      setValue('installation.name', dataLogger['installation']?.name);
      setValue('ftp.id', dataLogger['ftp']['id']);
    } else {
      setValue('name', '');
      setValue('identifier', '');
      setValue('installation.name', '');
      setValue('ftp.id', CREATE_NEW_FTP_ID);
    }

    return () => reset();
  }, [dataLogger]);

  useEffect(() => {
    const ftpId = watch('ftp.id');
    const ftp = ftps.find(({ id }) => id == ftpId);
    if (ftp != undefined) {
      setValue('ftp.host', ftp['host']);
      setValue('ftp.username', ftp['username']);
      setValue('ftp.password', ftp['password']);
    } else {
      setValue('ftp.host', '');
      setValue('ftp.username', '');
      setValue('ftp.password', '');
    }
  }, [watch('ftp.id'), ftps]);

  const onSubmit = (data: DataLogger) => execRequest({ data });

  const handleSaveFtp = () => {
    const { ftp } = watch();
    const data = { ftp };
    if (ftp['id'] === CREATE_NEW_FTP_ID) {
      execPostFtpRequest({ url: '/ftps', method: 'POST', data });
    } else {
      execPostFtpRequest({ url: `/ftps/${ftp['id']}`, method: 'PUT', data });
    }
  };

  const createFtpButtonEnabled = watch('ftp.host') != '' && watch('ftp.username') != '' && watch('ftp.password') != '';

  return (
    <Modal
      isOpen={visible}
      toggle={toggle}
      size="lg"
      header={`${isEdit ? 'Modify' : 'Create'} Data Logger`}
      isLoading={request.loading === 'LOADING'}
      submitAction={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input full {...field} label="common.name" />}
            />
          </Col>
          <Col md={4}>
            <Controller
              name="identifier"
              control={control}
              render={({ field }) => <Input full {...field} label="common.identifier" />}
            />
          </Col>
          <Col md={4} className="d-flex">
            <div style={{ alignSelf: 'flex-end' }}>
              <Controller
                name="type"
                defaultValue="webdyn"
                control={control}
                render={({ field }) => <Checkbox {...field} type="radio" checked disabled label="Webdyn" />}
              />
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <Controller
              name="installation.name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Select
                  label="common.filters.installation_name"
                  options={
                    installations?.map((inst) => ({
                      value: inst.name,
                      label: inst.name,
                    })) || []
                  }
                  selectedOption={field.value}
                  onChange={(option) => field.onChange(option?.value)}
                />
              )}
            />
          </Col>
          <Col md={12} className="mt-3">
            <Controller
              name="ftp.id"
              defaultValue={CREATE_NEW_FTP_ID}
              control={control}
              render={({ field }) => (
                <Select
                  options={ftps.map((ftp) => ({
                    value: ftp.id,
                    label: `host: ${ftp['host']} user: ${ftp['username']}`,
                  }))}
                  selectedOption={field.value}
                  onChange={(option) => field.onChange(option?.value)}
                  label="Ftp"
                />
              )}
            />
          </Col>
          <Col md={4} className="mt-3">
            <Controller
              name="ftp.host"
              control={control}
              render={({ field }) => <Input full {...field} label="Host" />}
            />
          </Col>
          <Col md={4} className="mt-3">
            <Controller
              name="ftp.username"
              control={control}
              render={({ field }) => <Input full {...field} label="common.username" />}
            />
          </Col>
          <Col md={4} className="mt-3">
            <Controller
              name="ftp.password"
              control={control}
              render={({ field }) => <Input full {...field} label="common.password" />}
            />
          </Col>
          <Col md={12} className="mt-3">
            <Button
              variant="primary-2"
              size="xs"
              onClick={handleSaveFtp}
              disabled={!createFtpButtonEnabled}
              isLoading={postFtpRequest.loading === 'LOADING'}
              text={watch('ftp.id') === CREATE_NEW_FTP_ID ? 'common.create_ftp' : 'common.edit_ftp'}
            />
          </Col>
        </Row>

        {request.error?.message ? <p className="text-red">{request.error.message}</p> : null}
      </form>
    </Modal>
  );
};
