import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export const Power = ({ row: { original } }: CellContext<Trade, unknown>) => {
  if (original?.power || original?.power === 0) {
    return <div>{original?.power?.toFixed(0)} MW</div>;
  }

  return null;
};
