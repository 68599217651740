import React from 'react';
import { HeadCell } from '@GDM/Table';
import { Facility } from '@pages/Trades/Trades/Table/Cells/Facility';
import { Horizon } from '@pages/Trades/Trades/Table/Cells/Horizon';
import { Price } from '@pages/Trades/Trades/Table/Cells/Price';
import { Revenue } from '@pages/Trades/Trades/Table/Cells/Revenue';
import { ColumnDef } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';
import { Power } from './Cells';

export const TRADE_COLUMNS = {
  POWER: {
    header: () => <HeadCell label="common.power" />,
    cell: Power,
    accessorKey: 'power',
  },

  HORIZON: {
    header: () => <HeadCell label="common.horizon" />,
    cell: Horizon,
    accessorKey: 'start_date',
  },

  REVENUE: {
    header: () => <HeadCell label="common.revenue" />,
    cell: Revenue,
    accessorKey: 'revenue',
  },

  PRICE: {
    header: () => <HeadCell label="common.price" />,
    cell: Price,
    accessorKey: 'price',
  },

  FACILITY: {
    header: () => <HeadCell label="common.scope" />,
    cell: Facility,
    accessorKey: 'facility',
  },
} as const satisfies Record<string, ColumnDef<Trade>>;
