import React, { useEffect } from 'react';
import { Button } from '@GDM/Button';
import useAxiosDeprecated from '@hooks/useAxios';
import fileDownload from 'js-file-download';
import { useInstallationsPageContext } from '../../context/installations-page.context';
import { FormMandatesProps } from '../FormMandates';

const cleanSirets = (sirets?: string) =>
  sirets
    ?.split(',')
    .flatMap((s) => s.split('\n'))
    .filter((s) => s !== '')
    .map((s) => s.replace(/^\s+|\s+$/g, ''));

const GeneratePDFButton = ({ form }: { form: FormMandatesProps['selectedMandate'] }) => {
  const { mandates } = useInstallationsPageContext();
  const [generateRequest, generate] = useAxiosDeprecated({ url: '/mandates/pdf', method: 'GET', responseType: 'blob' });
  const [downloadRequest, download] = useAxiosDeprecated({
    url: '/mandates/download',
    method: 'GET',
    responseType: 'blob',
  });
  const disabled = !form?.sirets || !form.signatoryName || !form.signatoryEmail;

  const handleDownload = () => {
    const selectedMandate = mandates?.find((m) => m.uuid === form?.mandateId);

    if (selectedMandate?.url) {
      download({ params: { mandate_id: selectedMandate.uuid } });
    } else {
      generate({
        params: {
          sirets: cleanSirets(form?.sirets),
          name: form?.signatoryName,
          email: form?.signatoryEmail,
          mandateId: form?.mandateId,
        },
      });
    }
  };

  useEffect(() => {
    if (generateRequest.loading === 'LOADED' && generateRequest.data) {
      fileDownload(generateRequest.data, 'mandate.pdf');
    }
  }, [generateRequest.loading]);

  useEffect(() => {
    if (downloadRequest.loading === 'LOADED') {
      fileDownload(downloadRequest.data, 'signed-mandate.pdf');
    }
  }, [downloadRequest]);

  return (
    <Button
      variant="primary-2"
      onClick={handleDownload}
      disabled={disabled}
      className="mr-3"
      isLoading={generateRequest.loading === 'LOADING'}
      icon="Download"
      size="xs"
      text={generateRequest.loading === 'LOADING' ? 'admin.installations.generating' : 'common.download'}
    />
  );
};

export default GeneratePDFButton;
