import type { RequestError } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import { recordToFormData } from '@utils/recordToFormData';
import { v2_unavailability_sharing_right_path, v2_unavailability_sharing_rights_path } from '@utils/routes';
import type Installation from '@utils/types/installation';
import type MarketPlayer from '@utils/types/market_player';
import type { SharingRight } from '@utils/types/unavailability';

export const getSharingRights = async () => {
  const response = await fetchWrapper(v2_unavailability_sharing_rights_path());
  const sharingRights: SharingRight[] = await response.json();

  return sharingRights;
};

export type SharingRightBody = Pick<SharingRight, 'reference' | 'sharing_status'> & {
  market_player_id?: MarketPlayer['id'] | null;
  credential_id?: Credential['id'] | null;
  installation_uuid?: Installation['uuid'] | null;
};

export const updateSharingRight = async (body: SharingRightBody, id: SharingRight['id']) => {
  const response = await fetchWrapper(v2_unavailability_sharing_right_path(id), {
    body: recordToFormData(body),
    method: 'PUT',
  });

  const json: SharingRight | RequestError = await response.json();

  if (!response.ok) {
    const errorMessage = 'message' in json ? json.message : 'errors.unknown_error';

    throw new Error(errorMessage);
  }

  return json as SharingRight;
};
