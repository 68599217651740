import { CFD_CONTRACTS } from '@utils/constants/contractTypesList';

export const CFD_CONTRACTS_INDEXATION_FORMULAS_MAP: Record<typeof CFD_CONTRACTS[number], string> = {
  ContractSwapPl: 'PL_AUCTION',
  ContractSwapUk: '1.0xCPI_UK',
  ContractSwapIe: '0.7+0.3xHICP',
  ContractSwapIt: '1.0xCPI_FOI_IT',
};

export const CFD_CONTRACTS_INDEXATION_FORMULAS = Object.values(CFD_CONTRACTS_INDEXATION_FORMULAS_MAP);

export const HYDRONEXT_INDEXATION = '0.75xICHTrevTS+0.25xFM0ABE0000';
