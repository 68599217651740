import React, { useEffect, useMemo } from 'react';
import { sectionStyles } from '@GDM/forms';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { ContractForm, PriceType } from '@utils/types/contract';
import { SPOT_POWER_EXCHANGES } from '@utils/types/contract/constants/spot-power-exchanges';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../hooks/useDateValidation';
import { NegPriceRule } from '../constants/negPriceRules';
import { PRICE_TYPE_OPTIONS_MAP } from '../constants/priceTypes';
import styles from '../sub-period.module.scss';

export function SwapIeSubPeriod({
  index,
  onDatePeriodClick,
  preSelectedPeriod,
  customPeriods,
  form: { control, setValue },
  datesValidation,
  readOnly,
  currency,
  priceTypeOptions,
  negativePriceRulesOptions,
}: {
  index: number;
  readOnly: boolean;
  form: UseFormReturn<ContractForm>;
  onDatePeriodClick: (period: string, index: number) => void;
  preSelectedPeriod: string | undefined;
  customPeriods:
    | {
        name: string;
        dates: [Date, Date];
      }[]
    | undefined;
  currency: Currency;
  datesValidation: DateValidators;
  priceTypeOptions: Option<PriceType | null>[];
  negativePriceRulesOptions: Option<NegPriceRule>[];
}) {
  const sourceOptions = useMemo(() => {
    return SPOT_POWER_EXCHANGES['IE'].map((source) => {
      return {
        label: source.toUpperCase(),
        value: source,
      };
    });
  }, []);

  const areaOptions = useMemo(
    () => [
      {
        label: 'IE',
        value: 'IE',
      },
    ],
    [],
  );

  useEffect(() => {
    setValue(`contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`, '1');
    setValue(`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`, 'spot');
    if (priceTypeOptions.length === 1) {
      setValue(`contract_sub_periods_attributes.${index}.price_type`, priceTypeOptions[0].value);
    }
    if (sourceOptions.length === 1) {
      setValue(
        `contract_sub_periods_attributes.${index}.price_model_attributes.power_exchange`,
        sourceOptions[0].value,
      );
    }
    if (areaOptions.length === 1) {
      setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.area`, areaOptions[0].value);
    }
    if (negativePriceRulesOptions.length === 1) {
      setValue(`contract_sub_periods_attributes.${index}.neg_price_rule`, negativePriceRulesOptions[0].value);
    }
  }, [index, setValue, sourceOptions, areaOptions, negativePriceRulesOptions, priceTypeOptions]);

  return (
    <div className={styles.section}>
      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        <DateInput
          data-description="SubPeriodDatesPicker"
          name={`contract_sub_periods_attributes.${index}.dates`}
          control={control}
          rules={{ validate: datesValidation }}
          data-cy={`dates-picker-${index}`}
          selectedPeriod={preSelectedPeriod}
          selectsRange
          showPeriodBadge
          customPeriods={customPeriods}
          onClickPeriod={(period) => onDatePeriodClick(period, index)}
          readOnly={readOnly}
          label="sales_management.sub_period.start_end_dates"
        />
      </div>

      <div className="mb-3">
        <div className={styles['subtitle']}>Auction Price</div>
        <div className={sectionStyles.row}>
          <NumericInput
            data-description="SwapFixedPrice"
            control={control}
            rules={{ required: true, max: 10000 }}
            name={`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`}
            data-cy={`swap-fixed-price-input-${index}`}
            label="sales_management.swap_ie_fixed_price"
            suffix={`${getSymbol(currency)}/MWh`}
            max={10000}
            step={1}
            required
            readOnly={readOnly}
            helpBox={{ title: 'sales_management.swap_ie_fixed_price', text: 'sales_management.swap_ie_spot_info' }}
          />
        </div>
      </div>

      <div className="mb-3">
        <div className={styles['subtitle']}>Market Reference</div>
        <div className={sectionStyles.row}>
          <ListInput
            data-description="SwapPriceTypePicker"
            name={`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`}
            control={control}
            rules={{ required: true }}
            classNamePrefix={`select-swap-price-type-${index}`}
            label="sales_management.swap_price_type"
            readOnly={readOnly}
            menuPlacement="top"
            options={[PRICE_TYPE_OPTIONS_MAP.spot]}
            isDisabled
          />

          <div className="wider">
            <ListInput
              id="NegPriceRulePicker"
              control={control}
              name={`contract_sub_periods_attributes.${index}.neg_price_rule`}
              label="sales_management.neg_price_rule"
              options={negativePriceRulesOptions}
              readOnly={readOnly}
              menuPlacement="top"
              classNamePrefix="select-neg-price-rule"
              isDisabled
            />
          </div>
        </div>
      </div>
    </div>
  );
}
