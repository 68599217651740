import React, { useMemo, useState } from 'react';
import type { SharingRightBody } from '@api/sharingRights';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useUser } from '@context/User.context';
import { RadioButtons } from '@GDM/forms';
import { Modal } from '@GDM/Modal';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { Text } from '@GDM/Text';
import { useCredentials } from '@hooks/requests/useCredentials';
import { useBuyerPlayers } from '@hooks/requests/useMarketPlayers';
import useTranslation from '@hooks/useTranslation';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type TableState,
} from '@tanstack/react-table';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { SharingRight } from '@utils/types/unavailability';
import omit from 'lodash/omit';
import { Controller, FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { SharingRightActions } from './SharingRightActions';
import { SharingRightFilters } from './SharingRightFilters';
import type { SharingRightsFiltersForm } from './Unavailabilities';
import { useColumns } from './useColumns';
import { useSharingRights, useUpdateSharingRight } from './useSharingRights';

/**
 * We pass both filters and filtersForm as parameters, because form.watch is not memoized and can cause infinite re-renders
 * when declaring it as the same level as other states
 */
export const SharingRightTable = ({
  filters,
  filtersForm,
}: {
  filters: SharingRightsFiltersForm;
  filtersForm: UseFormReturn<SharingRightsFiltersForm>;
}) => {
  const user = useUser();
  const [selectedSharingRightId, setSelectedSharingRightId] = useState<SharingRight['id'] | null>(null);

  const { data, isPending } = useSharingRights();
  const sharingRights = useMemo(() => data || [], [data]);

  const selectedSharingRight = useMemo(() => {
    return sharingRights.find((unavailabilitySharingRight) => unavailabilitySharingRight.id === selectedSharingRightId);
  }, [selectedSharingRightId, sharingRights]);

  const columns = useColumns(setSelectedSharingRightId);

  const columnFilters = useMemo(() => {
    return Object.entries(omit(filters, 'last_selected_filter')).map(([id, value]) => ({ id, value }));
  }, [filters]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const tableState = useMemo<Partial<TableState>>(() => {
    return {
      pagination,
      columnFilters,
      // We hide them, but we still need them for filtering purposes
      columnVisibility: { country: false, book: false },
    };
  }, [pagination, columnFilters]);

  const table = useReactTable({
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    data: sharingRights,
    columns,
    state: tableState,
    initialState: { sorting: [{ id: 'installation', desc: false }] },
    onPaginationChange: setPagination,
  });

  return (
    <>
      <FormProvider {...filtersForm}>
        <SharingRightFilters
          form={filtersForm}
          allSharingRights={sharingRights}
          filteredSharingsRights={table.getFilteredRowModel().rows.map((row) => row.original)}
        />
      </FormProvider>
      <SharingRightForm selectedSharingRight={selectedSharingRight} toggle={setSelectedSharingRightId} />

      <SharingRightActions getFilteredRowModel={table.getFilteredRowModel} locale={user.locale} />

      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} isLoading={isPending} />
      </Table>

      <TableActions className="p-0">
        <TablePageSizeSelect
          totalEntries={sharingRights.length}
          pageSize={table.getState().pagination.pageSize}
          setPageSize={table.setPageSize}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

const SharingRightForm = ({
  selectedSharingRight,
  toggle,
}: {
  selectedSharingRight: SharingRight | undefined;
  toggle: ToggleSharingRightsModal;
}) => {
  const { t } = useTranslation();
  const form = useForm<UnavailabilitySharingRightForm>({
    values: {
      /**
       * id and installation_uuid are required and must be set even if they are not shown in the form
       */
      id: selectedSharingRight?.id || -1,
      installation_uuid: selectedSharingRight?.installation?.uuid || '',
      reference: selectedSharingRight?.reference || '',
      market_player_id: selectedSharingRight?.market_player?.id || '',
      sharing_status: selectedSharingRight?.sharing_status || 'unshared',
      credential_id: selectedSharingRight?.credential?.id || '',
    },
  });

  const { mutate: update, isPending, error } = useUpdateSharingRight(toggle);
  const marketPlayersRequest = useBuyerPlayers(true);
  const credentialsRequest = useCredentials();
  const credentials = credentialsRequest.data || [];
  const country = selectedSharingRight?.installation?.country || 'FR';
  const marketPlayers = marketPlayersRequest.data
    ? marketPlayersRequest.data?.filter((mp) => mp.country === country || mp.operating_countries?.includes(country))
    : [];
  const marketPlayerOptions = marketPlayers.map((i) => ({ label: i.long_name, value: i.id })) || [];
  const selectedMarketPlayer = marketPlayers.find((i) => i.id === form.watch('market_player_id'));

  const credentialOptions = credentials
    .filter((credential) => selectedMarketPlayer && credential.source === selectedMarketPlayer?.source)
    .map((credential) => ({ label: credential.username, value: credential.id }));

  const isOpen = selectedSharingRight !== undefined;

  const handleSubmit = (data: UnavailabilitySharingRightForm) => {
    const { id, ...body } = data;

    update({ body, id });
  };

  return (
    <Modal
      header={t('monitoring.unavailabilities.sharing_rights_modal_title', {
        name: selectedSharingRight?.installation?.name || '',
      })}
      isOpen={isOpen}
      toggle={() => toggle(null)}
      submitAction={form.handleSubmit(handleSubmit)}
      isLoading={isPending}
      footer={error && <Text multiline text={error?.message} type="danger" />}
    >
      <div className="d-flex flex-column gap-3">
        <ControlledSelect
          control={form.control}
          name="market_player_id"
          label="monitoring.unavailabilities.aggregator"
          options={marketPlayerOptions.sort(sortOptionsByLabelAsc)}
          size="lg"
          rules={{ required: true }}
          isLoading={marketPlayersRequest.isPending}
        />
        {/* I've encountered an issue with ControlledRadioButtons where the value was undefined even we set in values
         * Therefore I choose to use Controller
         */}
        <Controller
          control={form.control}
          name="sharing_status"
          rules={{ required: true }}
          render={({ field }) => (
            <RadioButtons
              label="monitoring.unavailabilities.sharing_permission"
              name={field.name}
              onChange={field.onChange}
              selected={field.value}
              options={[
                { label: 'monitoring.unavailabilities.shared', value: 'shared' },
                { label: 'monitoring.unavailabilities.unshared', value: 'unshared' },
              ]}
              size="lg"
              disabled={!selectedMarketPlayer?.unavailabilities_managed_by_streem}
            />
          )}
        />
        {selectedMarketPlayer?.has_external_ref && (
          <ControlledInput
            control={form.control}
            name="reference"
            label="monitoring.unavailabilities.external_ref"
            size="lg"
            full
            disabled={!selectedMarketPlayer?.unavailabilities_managed_by_streem}
          />
        )}
        <ControlledSelect
          control={form.control}
          name="credential_id"
          label="monitoring.unavailabilities.credentials"
          options={credentialOptions.sort(sortOptionsByLabelAsc)}
          isLoading={credentialsRequest.isPending}
          tooltip="monitoring.unavailabilities.credentials_input_tooltip"
          isDisabled={credentialOptions.length === 0 || !selectedMarketPlayer?.unavailabilities_managed_by_streem}
          size="lg"
          isClearable
        />
      </div>
    </Modal>
  );
};

export type ToggleSharingRightsModal = (id: SharingRight['id'] | null) => void;

type UnavailabilitySharingRightForm = SharingRightBody & {
  id: SharingRight['id'];
};
