import fetchWrapper from '@utils/fetch';
import { v2_credentials_path } from '@utils/routes';
import { Credential } from '@utils/types/credential';

export type CredentialSource = 'edf_oa' | 'cegedim' | 'pge' | 'edistribuzione' | 'enedis_dispo_reseau' | 'vestas';

export const getCredentials = async (source?: CredentialSource): Promise<Credential[]> => {
  const response = await fetchWrapper(v2_credentials_path({ source }));

  return response.json();
};
