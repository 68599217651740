import classNames from 'classnames';
import { REGULAR_COLUMN_METRICS } from './constants';

export const getUnitFromMetric = (metric: string) => {
  return classNames({
    GWh: [...REGULAR_COLUMN_METRICS, 'Volume', 'Price Outright', 'Profile Risk', 'Balancing Risk'].includes(metric),
    '%': ['HCR', 'Hedge Covering Ratio'].includes(metric),
    '€/MWh': ['Net Price', 'Float Price', 'OA/CfD Price'].includes(metric),
    'M€': 'MtoM' === metric,
  });
};
