import React, { useEffect } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer, Filters, useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { EnergyType, Option } from '@utils/types/common-types';
import type { CountryCode } from '@utils/types/countries';
import type { SharingRight } from '@utils/types/unavailability';
import { type UseFormReturn } from 'react-hook-form';
import type { SharingRightsFiltersForm } from './Unavailabilities';

export const SharingRightFilters = ({
  form,
  allSharingRights,
  filteredSharingsRights,
}: {
  form: UseFormReturn<SharingRightsFiltersForm>;
  allSharingRights: SharingRight[];
  filteredSharingsRights: SharingRight[];
}) => {
  const { control, watch, setValue } = form;

  const installationOptions = useDynamicOptions(
    getInstallationOptionsFromSharingRights,
    'installation',
    filteredSharingsRights,
    allSharingRights,
  );
  const countryOptions = useDynamicOptions(
    getCountryOptionsFromSharingRights,
    'country',
    filteredSharingsRights,
    allSharingRights,
  );
  const bookOptions = useDynamicOptions(
    getBookOptionsFromSharingRights,
    'book',
    filteredSharingsRights,
    allSharingRights,
  );
  const aggregatorOptions = useDynamicOptions(
    getAggregatorOptionsFromSharingRights,
    'aggregator',
    filteredSharingsRights,
    allSharingRights,
  );

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name && name !== 'last_selected_filter' && type === 'change') {
        setValue('last_selected_filter', name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Filters className="p-0">
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="installation"
          options={installationOptions}
          isInstallationOrBook
          placeholder="common.installation"
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="country"
          options={countryOptions}
          placeholder="common.country"
          isCountry
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="book"
          options={bookOptions}
          placeholder="common.book"
          isClearable
          isMulti
          isInstallationOrBook
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="aggregator"
          options={aggregatorOptions}
          placeholder="monitoring.unavailabilities.aggregator"
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="formatted_sharing_status"
          options={[
            { label: 'monitoring.unavailabilities.shared', value: 'shared' },
            { label: 'monitoring.unavailabilities.unshared', value: 'unshared' },
            { label: 'monitoring.unavailabilities.not_available', value: 'not_available' },
          ]}
          placeholder="monitoring.unavailabilities.sharing_permission"
          isClearable
        />
      </FilterContainer>
    </Filters>
  );
};

const getOptionsFromSharingRights = (sharingRights: SharingRight[]) => {
  const allInstallationsMap = new Map<string, Option<string> & { energy: EnergyType }>();
  const allBooksMap = new Map<string, Option<string> & { energy: EnergyType }>();
  const allAggregatorsMap = new Map<string, Option<string>>();
  const countryMap = new Map<CountryCode, Option<CountryCode>>();

  sharingRights.forEach((unavailabilitySharingRight) => {
    const { market_player: marketPlayer, installation } = unavailabilitySharingRight;

    if (installation) {
      allInstallationsMap.set(installation.name, {
        value: installation.name,
        label: installation.name,
        energy: installation.energy,
      });

      if (installation.country) {
        countryMap.set(installation.country, { value: installation.country, label: installation.country });
      }

      installation.books.forEach((book) => {
        allBooksMap.set(book.name, { value: book.name, label: book.name, energy: 'book' });
      });
    }

    if (marketPlayer) {
      allAggregatorsMap.set(marketPlayer.long_name, { value: marketPlayer.long_name, label: marketPlayer.long_name });
    }
  });

  return {
    installationOptions: Array.from(allInstallationsMap.values()),
    countryOptions: Array.from(countryMap.values()),
    bookOptions: Array.from(allBooksMap.values()),
    aggregatorOptions: Array.from(allAggregatorsMap.values()),
  };
};

const createGetOptionsFromSharingRights =
  (name: keyof ReturnType<typeof getOptionsFromSharingRights>) => (sharingRights: SharingRight[]) => {
    return getOptionsFromSharingRights(sharingRights)[name].sort(sortOptionsByLabelAsc);
  };

const getInstallationOptionsFromSharingRights = createGetOptionsFromSharingRights('installationOptions');
const getCountryOptionsFromSharingRights = createGetOptionsFromSharingRights('countryOptions') as (
  sharingRights: SharingRight[],
) => Option<CountryCode>[];
const getBookOptionsFromSharingRights = createGetOptionsFromSharingRights('bookOptions');
const getAggregatorOptionsFromSharingRights = createGetOptionsFromSharingRights('aggregatorOptions');
