import React from 'react';
import { useRequest } from '@hooks/useRequest';
import InstallationBooks from '@ui/InstallationBooks';
import { v2_meter_books_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { CharacteristicsSection } from '../../shared/CharacteristicsSection/CharacteristicsSection';

export const Books = ({ meter }: { meter: Meter }) => {
  const { data: books, loading } = useRequest<{ uuid: string; name: string }[]>(v2_meter_books_path(meter.name), 'GET');

  if (!books?.length) return null;

  const bookNames = books.map((book) => book.name);

  return (
    <CharacteristicsSection title="common.books" isLoading={loading}>
      {!!books?.length && !loading && <InstallationBooks books={bookNames} />}
    </CharacteristicsSection>
  );
};
