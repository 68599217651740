import axiosInstance from '@utils/axiosInstance';
import { v2_windmill_path, v2_windmills_path } from '@utils/routes';
import type { Windmill } from '@utils/types/turbine';

type WindmillBody = Pick<Windmill, 'turbine_id' | 'power' | 'ref_manufacturer' | 'name' | 'ref_aggregator'>;

export const getWindmills = async (installationName: string) => {
  return (await axiosInstance(false).get<Windmill[]>(v2_windmills_path(installationName))).data;
};

export const updateWindmill = async (id: Windmill['id'], windmill: WindmillBody) => {
  return (await axiosInstance(false).put<Windmill>(v2_windmill_path(id), { windmill })).data;
};

export const destroyWindmill = async (id: Windmill['id']) => {
  return (await axiosInstance(false).delete<Pick<Windmill, 'id'>>(v2_windmill_path(id))).data;
};

export const createWindmill = async (installationName: string, windmill: WindmillBody) => {
  return (await axiosInstance(false).post<Windmill>(v2_windmills_path(installationName), windmill)).data;
};
