import React from 'react';
import type { FilterType } from '@GDM/Filters/filters.types';
import type { LayoutProps } from '@pages/Page/Page';
import Page from '@pages/Page/Page';
import type { CountryCode } from '@utils/types/countries';
import type { SharingRight } from '@utils/types/unavailability';
import { useForm } from 'react-hook-form';
import { SharingRightTable } from './SharingRightTable';

const Unavailabilities = ({ user }: LayoutProps) => {
  const filtersForm = useForm<SharingRightsFiltersForm>({
    defaultValues: {
      installation: [],
      country: [],
      book: [],
      aggregator: [],
    },
  });
  const filters = filtersForm.watch();

  return (
    <Page user={user} title="sidebar.unavailabilities" layout="no-background">
      <SharingRightTable filters={filters} filtersForm={filtersForm} />
    </Page>
  );
};

export default Unavailabilities;

export type SharingRightsFiltersForm = FilterType<{
  installation: string[];
  country: CountryCode[];
  book: string[];
  aggregator: string[];
  formatted_sharing_status: SharingRight['sharing_status'] | null;
}>;
