import { getSellers } from '@api/sellers';
import { useQuery } from '@tanstack/react-query';
import MarketPlayer from '@utils/types/market_player';

export const useSellers = () => {
  return useQuery<MarketPlayer[], Error>({
    queryKey: ['sellers'],
    queryFn: getSellers,
  });
};
