import React from 'react';
import { Spinner } from '@GDM/Spinner';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './characteristics-section.module.scss';

export const CharacteristicsSection = ({
  title,
  isLoading,
  children,
  className,
}: {
  title: string | React.ReactNode;
  isLoading?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={classNames(styles.section, className)}>
      {typeof title === 'string' && <CharacteristicsSectionTitle title={title} isLoading={isLoading} />}
      {typeof title !== 'string' && title}

      <div className="mt-3">{children}</div>
    </div>
  );
};

const CharacteristicsSectionTitle = ({
  title,
  isLoading,
  children,
}: {
  title: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex">
      <div className="d-flex tt:uppercase fw:700">
        <span className="mr-2">{t(title).toUpperCase()}</span>
        {isLoading && <Spinner size="sm" />}
      </div>

      {children && <div className="ml-auto">{children}</div>}
    </div>
  );
};

const CharacteristicsSectionContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.container}>{children}</div>;
};

const CharacteristicsSectionColumn = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.column}>{children}</div>;
};

CharacteristicsSection.Container = CharacteristicsSectionContainer;
CharacteristicsSection.Title = CharacteristicsSectionTitle;
CharacteristicsSection.Column = CharacteristicsSectionColumn;
