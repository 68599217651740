import React from 'react';
import { Filters, FilterContainer } from '@GDM/Filters';
import { MandateStatus } from '@utils/types/mandate';
import { useForm } from 'react-hook-form';
import { useInstallationsPageContext } from '../../../context/installations-page.context';
import { MandateNameFilter } from './MandatesNameFilter';
import { MandatesStatusFilter } from './MandatesStatusFilter';
import { useMandateFilters } from './useMandateFilter';

export type MandateFiltersType = {
  representative: string;
  status: MandateStatus | string;
};

export const MandateFilter = () => {
  const form = useForm<MandateFiltersType>({
    defaultValues: {
      representative: '',
      status: '',
    },
  });

  const { mandates, setFilteredMandates } = useInstallationsPageContext();
  const filterMandates = useMandateFilters(mandates || []);
  const handleFilterChange = (params: MandateFiltersType) => {
    setFilteredMandates?.(filterMandates(params));
  };

  return (
    <Filters form={form}>
      <FilterContainer>
        <MandateNameFilter onChange={handleFilterChange} />
      </FilterContainer>
      <FilterContainer>
        <MandatesStatusFilter onChange={handleFilterChange} />
      </FilterContainer>
    </Filters>
  );
};
