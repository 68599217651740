import React, { useEffect, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Button } from '@GDM/Button';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_meter_analytics_solar_champs_path } from '@utils/routes';
import Panel from '@utils/types/panel';
import { CharacteristicsSection } from '../CharacteristicsSection/CharacteristicsSection';
import { PanelDeleteModal } from './PanelDeleteModal';
import { PanelModalForm } from './PanelModalForm';
import { PanelTable } from './PanelTable';

export const Panels = ({ meterName }: { meterName: string }) => {
  const [visible, setVisible] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState<Panel | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { t } = useTranslation();
  const { data: solarFields, loading } = useRequest<Panel[]>(v2_meter_analytics_solar_champs_path(meterName), 'GET');
  const [panels, setPanels] = useState<Panel[]>(solarFields || []);

  useEffect(() => {
    setPanels(solarFields || []);
  }, [solarFields]);

  const toggle = () => {
    if (visible) {
      setSelectedPanel(null);
    }

    setVisible(!visible);
  };

  const toggleDeleteModal = () => {
    if (deleteModalVisible) {
      setSelectedPanel(null);
    }

    setDeleteModalVisible(!deleteModalVisible);
  };

  const handleEditClick = (panel: Panel) => () => {
    setVisible(() => {
      setSelectedPanel(panel);

      return !visible;
    });
  };

  const addPanel = (panel: Panel) => {
    const panelsClone = [...panels];

    if (panel?.uuid) {
      const index = panelsClone.findIndex((p) => p.uuid === panel.uuid);

      if (index > -1) {
        panelsClone[index] = panel;
      } else {
        panelsClone.push(panel);
      }
    } else {
      panelsClone.push(panel);
    }

    setPanels(panelsClone);
    toggle();
  };

  const deletePanel = (uuid: Panel['uuid']) => {
    const panelIndex = panels?.findIndex((p) => p.uuid === uuid);

    if (panelIndex > -1) {
      const panelsClone = panels?.filter((p, currentIndex) => currentIndex !== panelIndex);
      setPanels(panelsClone);
    }

    toggleDeleteModal();
  };

  const openDeleteModal = (panel: Panel) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedPanel(panel);
    toggleDeleteModal();
  };

  return (
    <CharacteristicsSection
      title={
        <CharacteristicsSection.Title title="monitoring.panels.title" isLoading={loading}>
          <Restricted permissions={['display:edit_monitoring']}>
            <Button
              className="mr-0"
              variant="primary-2"
              size="xs"
              onClick={toggle}
              icon="Plus"
              title={t('common.add_new')}
            >
              {t('common.add_new')}
            </Button>
          </Restricted>
        </CharacteristicsSection.Title>
      }
    >
      <PanelModalForm
        toggle={toggle}
        visible={visible}
        selectedPanel={selectedPanel}
        meterName={meterName}
        addPanel={addPanel}
      />
      <PanelDeleteModal
        toggle={toggleDeleteModal}
        visible={deleteModalVisible}
        selectedPanel={selectedPanel}
        meterName={meterName}
        deletePanel={deletePanel}
      />

      {panels?.length > 0 && (
        <PanelTable panels={panels} openDeleteModal={openDeleteModal} handleEditClick={handleEditClick} />
      )}
      {!panels?.length && !loading && <AltContent>{t('monitoring.panels.none')}</AltContent>}
    </CharacteristicsSection>
  );
};
