import React from 'react';
import '../trade_styles.css';
import { useTrades } from '@hooks/requests/useTrades';
import Page from '@pages/Page';
import { TradeForm } from '@pages/Trades/TradeForm/TradeForm';
import { User } from '@utils/types/user';
import { TradesTable } from './Table';

interface TradesProps {
  user: User;
}

export const Trades = ({ user }: TradesProps) => {
  const { data: trades, isLoading, error } = useTrades();

  return (
    <Page
      title="sales_management.trades"
      error={error?.message}
      isLoading={isLoading}
      user={user}
      layout="no-background"
      fixedHeightContainer
      useQueryClient={false}
    >
      <div className="trade-container">
        <TradeForm />
        {!isLoading && trades && <TradesTable trades={trades} />}
      </div>
    </Page>
  );
};
