import React from 'react';
import { Section, sectionStyles } from '@GDM/forms';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { NumericInput } from '../../components/Inputs/Numeric';
import { SectionHeader } from '../../components/SectionHeader';

export const Cap = ({ title, readOnly, form: { control, watch } }: ContractFormSectionProps) => {
  const subType = watch('sub_type');

  const contractType = watch('type');

  let hasAnnualCap = true;
  if (contractType === 'ContractSwapIe') hasAnnualCap = false;

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          <div className={sectionStyles.row}>
            {hasAnnualCap && (
              <NumericInput
                id="AnnualCap"
                control={control}
                rules={{ required: subType ? CAPPED_SUB_TYPES.includes(subType) : false }}
                name="annual_cap"
                label="sales_management.annual_cap"
                readOnly={readOnly}
                suffix="kWh"
                data-cy="annual-cap"
                helpBox={{ title: 'sales_management.annual_cap', text: 'contracts.info.annual_cap' }}
              />
            )}
            <NumericInput
              id="PMax"
              control={control}
              name="p_max"
              label="sales_management.p_max"
              readOnly={readOnly}
              suffix="kW"
              data-cy="p-max"
              helpBox={{ title: 'sales_management.p_max', text: 'contracts.info.p_max' }}
            />
          </div>
        </Section>
      }
    />
  );
};

const CR_EDF_CAPPED_SUB_TYPES = [
  'cr17',
  'E17_V1.0.1',
  'E17_V1.0.2',
  'E17_V2',
  'FSE17CR',
  'FSI_17_CR',
  'FV16SCR_V01',
  'FV16SCR_V2.0.0',
  'FV16SCR_V2.0.1',
  'FV21SCR_V02',
];

const OA_CAPPED_SUB_TYPES = [
  'Photo_2006_V1',
  'Photo_2006_V3',
  'Photo_2010_V1',
  'Photo_2011M_V1',
  'Photo_2011M_V2',
  'Photo_2011S_V1',
  'Photo_2011_V1',
  'FS11',
  'FS13',
  'FV11_SF3_V2',
  'FV11_SF4_V2',
  'FV11_SF5_V1',
  'FV11_SF6',
  'FV11_SF6_V1',
  'FV11_SF6_V2',
  'FV11_SF7_V2',
  'FV13_V1',
  'FV14_V1',
  'FV16BOA_V01',
  'FV16BOA_V02',
];

const CAPPED_SUB_TYPES = [...CR_EDF_CAPPED_SUB_TYPES, ...OA_CAPPED_SUB_TYPES];
