import { createContext, useContext } from 'react';
import isUserAuthorized from '@utils/isUserAuthorized';
import { getUserTimezone } from '@utils/timezones';
import { Timezone } from '@utils/types/countries';
import { Permission } from '@utils/types/permission';
import { User } from '@utils/types/user';

export type UserCtxType = User & {
  isAuthorized: (permissionsRequired: Permission[]) => boolean;
  isAggregator: boolean;
  isConsumer: boolean;
  isOnlyUnavailabilities: boolean;
  timezone: Timezone;
};

const UserCtx = createContext<UserCtxType>({
  isAuthorized: () => false,
  uuid: '',
  account_type: 'producer',
  access_admin_installations: false,
  admin: null,
  asset_report: false,
  billing: false,
  email: '',
  full_merchant: false,
  geolocation: [],
  has_access_to_reports: false,
  limited: true,
  launchtr: false,
  locale: 'fr',
  monitoring: false,
  name: '',
  permissions: [],
  pricing: false,
  roles: [],
  sales_management: false,
  status: '',
  societe: '',
  adresse: '',
  siren: '',
  invoice_manager_emails: [],
  isAggregator: false,
  isConsumer: false,
  timezone: 'Europe/Paris',
  currencies: [],
  isOnlyUnavailabilities: false,
});

export const useUserContext = (user: User): UserCtxType => {
  if (!user)
    // eslint-disable-next-line no-console
    console.warn("No value for the User object was passed in the <Page/> context. Permissions won't Work properly.");

  if (user) localStorage.setItem('locale', user?.locale);

  const isAuthorized = (permissions: Permission[]) => isUserAuthorized(user, permissions);
  const isAggregator = user.account_type === 'aggregator';
  const isConsumer = user.account_type === 'consumer';
  const timezone = getUserTimezone(user);
  const isOnlyUnavailabilities = user.roles?.length === 1 && user.roles.includes('viewer_unavailabilities');

  return { ...user, isAuthorized, isAggregator, isConsumer, timezone, isOnlyUnavailabilities };
};

export const useUser = (): UserCtxType => useContext(UserCtx);

export default UserCtx;
