import React, { useState, useCallback } from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { useSellers } from '@hooks/requests/useSellers';
import { useTradesCreateMutation } from '@hooks/requests/useTrades';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { useForm, type UseFormReturn } from 'react-hook-form';

export type TradeForm = {
  price: number;
  power: number;
  start_date: Date;
  end_date: Date;
  seller_id: string;
};

const defaultValues: TradeForm = {
  price: 0.0,
  power: 0.0,
  start_date: new Date(),
  end_date: new Date(),
  seller_id: '',
};

export const TradeForm = () => {
  const form = useForm<TradeForm>({ defaultValues });
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const toggleCreateModal = useCallback(() => {
    setCreateModalOpen((prev) => !prev);
    form.reset(defaultValues);
  }, [form]);

  return (
    <Restricted permissions={['create:trade']}>
      <div className="trade-header">
        <Button
          className="add-trade-button"
          variant="primary-2"
          size="xs"
          icon="Plus"
          text="Add New Trade"
          onClick={toggleCreateModal}
        />
      </div>

      <TradeModal isOpen={createModalOpen} toggle={toggleCreateModal} form={form} />
    </Restricted>
  );
};

const TradeModal = ({
  form,
  isOpen,
  toggle,
}: {
  form: UseFormReturn<TradeForm>;
  isOpen: boolean;
  toggle: () => void;
}) => {
  const createMutation = useTradesCreateMutation(toggle);
  const sellerQuery = useSellers();

  const sellersOptions =
    sellerQuery.data?.map((market_player) => ({
      value: market_player.id,
      label: market_player.short_name,
    })) || [];

  const handleSubmit = (data: TradeForm) => {
    const enrichedData = {
      ...data,
      id: undefined,
      start_date: data.start_date.toISOString(),
      end_date: data.end_date.toISOString(),
      facility_type: 'User' as 'User' | 'Installation' | 'Book',
      facility_id: undefined,
    };

    createMutation.mutate(enrichedData);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} submitAction={form.handleSubmit(handleSubmit)} header="trades.add_trade">
      <div className="d-flex flex-column gap-3">
        <ControlledInput
          control={form.control}
          label="common.price"
          name="price"
          type="number"
          step="0.01"
          size="lg"
          full
          rules={{ required: 'Price is required' }}
        />
        <ControlledInput
          control={form.control}
          label="common.power"
          name="power"
          type="number"
          step="0.01"
          size="lg"
          full
          rules={{ required: 'Power is required' }}
        />
        <div className="d-flex gap-3">
          <DateInput
            control={form.control}
            label="Start Date"
            name="start_date"
            helpBox={{ title: 'Start Date', text: 'Select the starting date for the trade.' }}
          />
          <DateInput
            control={form.control}
            label="End Date"
            name="end_date"
            helpBox={{ title: 'End Date', text: 'Select the ending date for the trade.' }}
          />
        </div>
        <ControlledSelect
          control={form.control}
          label="Seller"
          name="seller_id"
          options={sellersOptions}
          classNamePrefix="select-buyer"
          size="lg"
          rules={{ required: 'Seller is required' }}
        />
      </div>
    </Modal>
  );
};
