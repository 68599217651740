export const PRICE_TYPES = ['fixed', 'spot', 'prefcapa', 'm0', 'imbalance_pos', 'indexed'] as const;

export const NEG_PRICE_POLICIES = [
  'zero',
  'spot',
  'imbalance_pos',
  'imbalance_pos_only_neg',
  'imbalance_pos_with_fees',
  'imbalance_pos_with_2_euro_fees',
  'm0',
] as const;

export const CONTRACT_DISPOSITIFS = ['guichet_ouvert', 'ao_cre'] as const;

export const ALLOCATION_ROUND = ['AR1', 'AR2', 'AR5'] as const;
