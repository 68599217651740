import { useMemo } from 'react';
import { TRADE_COLUMNS } from '@pages/Trades/Trades/Table/ColumnList';
import { ColumnDef } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export function useColumns() {
  const columns = useMemo<ColumnDef<Trade>[]>(() => {
    return [
      TRADE_COLUMNS.FACILITY,
      TRADE_COLUMNS.HORIZON,
      TRADE_COLUMNS.POWER,
      TRADE_COLUMNS.PRICE,
      TRADE_COLUMNS.REVENUE,
    ];
  }, []);

  return { columns };
}
