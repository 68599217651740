import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Chart } from '@GDM/Chart/Chart';
import { NumberCell } from '@GDM/Table/Cells/NumberCell/NumberCell';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableFooter } from '@GDM/Table/TableFooter/TableFooter';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import {
  AccessorKeyColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  VALUATION_METRICS_PER_TYPE,
  SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS,
  VALUATION_METRICS_FORM_NAMES_TO_LABELS,
  SENSITIVITY_METRICS_PER_TYPE,
} from '../constants';
import styles from '../styles.module.scss';
import {
  RiskAnalysisReportType,
  GreenstarReportData,
  RiskAnalysisFormFilters,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
  SensitivityMetricType,
} from '../types';
import { GreenstarRow, useRiskAnalysisTable } from './hooks/table';
import { useRiskAnalysisCharts } from './hooks/useCharts';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReportVisualisation = ({
  data: rawData,
  isReportNested,
  isSplitByMetric: rawIsSplitByMetric,
  type,
  metricGroup,
  display,
  title,
  splitRootRowsIntoTables,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  isSplitByMetric: boolean;
  type: RiskAnalysisReportType;
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
  display: RiskAnalysisFormFilters['display'];
  title?: string;
  splitRootRowsIntoTables?: boolean;
}) => {
  const isSensitivityChart = display === 'chart' && type === 'sensitivity';
  const isSplitByMetric = isSensitivityChart || rawIsSplitByMetric;
  const intermediateData = useFormatReportData(rawData, isReportNested, isSplitByMetric);
  const data = isSensitivityChart
    ? { Total: intermediateData.Total as GreenstarReportDataWithOneFocus }
    : intermediateData;

  const { columns, rows, dates } = useRiskAnalysisTable(data, isReportNested);

  const metricsToLabel =
    type === 'valuation' ? VALUATION_METRICS_FORM_NAMES_TO_LABELS : SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS;

  const metricsPerType = type === 'valuation' ? VALUATION_METRICS_PER_TYPE : SENSITIVITY_METRICS_PER_TYPE;

  const columnFilters = useMemo<[{ id: string; value: string[] }] | []>(() => {
    if (metricGroup === 'all') {
      return [];
    } else {
      const blackList = Object.entries(metricsPerType).reduce<string[]>(
        (list, [metricType, metrics]) => (metricType === metricGroup ? list : [...list, ...metrics]),
        [],
      );

      return [
        {
          id: 'name',
          value: blackList.map((metric) => {
            return metricsToLabel[metric as keyof typeof metricsToLabel];
          }),
        },
      ];
    }
  }, [metricGroup, metricsPerType, metricsToLabel]);

  const { totalVolumeRow, averagePriceRow } = useMemo(() => {
    const totalVolumeRow = {
      name: 'Total Volume',
      unit: 'GWh',
      subRows: [],
      values: dates.reduce((vls, date) => {
        let sum = 0;

        if (isReportNested) {
          rows.forEach((firstFocusRow) => {
            firstFocusRow.subRows.forEach((secondFocusRow) => {
              const isVolume = firstFocusRow.name === 'Volume' || secondFocusRow.name === 'Volume';
              secondFocusRow.subRows.forEach((metricRow) => {
                if (isVolume || metricRow.name === 'Volume') {
                  sum += Number(metricRow.values[date]);
                }
              });
            });
          });
        } else {
          rows.forEach((firstFocusRow) => {
            const isVolume = firstFocusRow.name === 'Volume';
            firstFocusRow.subRows.forEach((metricRow) => {
              if (isVolume || metricRow.name === 'Volume') {
                sum += Number(metricRow.values[date]);
              }
            });
          });
        }

        return { ...vls, [date]: sum };
      }, {} as GreenstarReportMetricValues),
    };

    const totalMtoMValues = dates.reduce((vls, date) => {
      let sum = 0;

      if (isReportNested) {
        rows.forEach((firstFocusRow) => {
          firstFocusRow.subRows.forEach((secondFocusRow) => {
            const isMtoM = firstFocusRow.name === 'MtoM' || secondFocusRow.name === 'MtoM';
            secondFocusRow.subRows.forEach((metricRow) => {
              if (isMtoM || metricRow.name === 'MtoM') {
                sum += Number(metricRow.values[date]);
              }
            });
          });
        });
      } else {
        rows.forEach((firstFocusRow) => {
          const isMtoM = firstFocusRow.name === 'MtoM';
          firstFocusRow.subRows.forEach((metricRow) => {
            if (isMtoM || metricRow.name === 'MtoM') {
              sum += Number(metricRow.values[date]);
            }
          });
        });
      }

      return { ...vls, [date]: sum };
    }, {} as GreenstarReportMetricValues);

    const averagePriceRow = {
      name: 'Average Price',
      unit: '€/MWh',
      subRows: [],
      values: Object.entries(totalVolumeRow.values).reduce((vals, [date, totalVolume]) => {
        const volume = Number(totalVolume);
        const mtom = Number(totalMtoMValues[date]);

        return {
          ...vals,
          [date]: volume && !isNaN(mtom) ? (mtom * 1000) / volume : '',
        };
      }, {} as GreenstarReportMetricValues),
    };

    return { totalVolumeRow, averagePriceRow };
  }, [dates, isReportNested, rows]);

  const actualColumns =
    type === 'valuation'
      ? columns.map((column) => ({
          ...column,
          footer: () =>
            column.id === 'name' ? (
              <div className={styles['footer-wrapper']}>
                <div className={styles['footer-row']}>Total Volume</div>
                <div className={styles['footer-row']}>Average Price</div>
              </div>
            ) : (
              <div className={styles['footer-wrapper']}>
                <div className={styles['footer-row']}>
                  <NumberCell value={totalVolumeRow.values[column.id]} unit="GWh" fractionDigits={2} />
                </div>
                <div className={styles['footer-row']}>
                  <NumberCell value={averagePriceRow.values[column.id]} unit="EUR/MWh" fractionDigits={2} />
                </div>
              </div>
            ),
        }))
      : columns;

  const chartsDataList = useRiskAnalysisCharts({
    data,
    isReportNested,
    splitByMetric: isSplitByMetric,
    metricsBlackList: columnFilters?.[0]?.value || [],
  });

  const charts = chartsDataList.map((chart) => (
    <Chart
      {...chart}
      options={{ ...chart.options, title: { ...chart.options.title, text: title || chart.options.title?.text } }}
      key={chart.key}
    />
  ));

  return (
    <>
      {display === 'table' &&
        (splitRootRowsIntoTables ? (
          rows
            .filter(
              ({ name }) =>
                metricGroup === 'all' ||
                SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS[metricGroup as SensitivityMetricType] === name,
            )
            .map((row) => (
              <TableViz
                key={row.name}
                columns={actualColumns}
                rows={row.subRows}
                columnFilters={columnFilters}
                type={type}
                title={row.name}
              />
            ))
        ) : (
          <TableViz columns={actualColumns} rows={rows} columnFilters={columnFilters} type={type} title={title} />
        ))}
      {display === 'chart' && (isSensitivityChart ? charts : <div className={styles['chart-grid']}>{charts}</div>)}
    </>
  );
};

const TableViz = ({
  columns,
  rows,
  columnFilters,
  title,
  type,
}: {
  title?: string;
  type: RiskAnalysisReportType;
  rows: GreenstarRow[];
  columns: (AccessorKeyColumnDef<GreenstarRow> & {
    id: string;
  })[];
  columnFilters:
    | [
        {
          id: string;
          value: string[];
        },
      ]
    | [];
}) => {
  const [expanded, setExpanded] = useState({});

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    state: {
      expanded,
      columnFilters,
    },
    enableSorting: false,
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useLayoutEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  return (
    <>
      {title && <h3 className="upper-case">{title}</h3>}
      <Table>
        <TableHead table={table} />
        <TableBody table={table} />
        {type === 'valuation' && <TableFooter table={table} />}
      </Table>
    </>
  );
};
