import React from 'react';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import type { Currency } from '@utils/types/currency';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../update-modal.module.scss';
import type { UpdateInvoiceForm } from '../update-modal.types';

export const UnitPriceInput = ({
  index,
  in_cents_per_kWh,
  currency,
}: {
  index: number;
  in_cents_per_kWh: boolean;
  currency: Currency;
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<UpdateInvoiceForm>();
  const { revenue_type: revenueType } = watch(`revenues.${index}`);
  const suffix = in_cents_per_kWh ? `c${getSymbol(currency)}/kWh` : `${getSymbol(currency)}/MWh`;

  return (
    <Controller
      control={control}
      name={`revenues.${index}.unit_price`}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Input
          className={styles.input}
          suffix={suffix}
          step="1"
          errorMessage={
            fieldState.error && t('common.is_required_dynamic', { key: t('sales_management.invoices.unit_price') })
          }
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
          data-cy={`revenues.${revenueType}.unit_price`}
        />
      )}
    />
  );
};
