import React from 'react';
import { NumberCell } from '@GDM/Table';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export const Revenue = ({ row: { original } }: CellContext<Trade, unknown>) => {
  if (original?.revenue || original?.revenue === 0) {
    return (
      <NumberCell
        value={original.revenue}
        numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
        unit="EUR"
      />
    );
  }

  return null;
};
