import React from 'react';
import { useUser, type UserCtxType } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { iconDictionary } from '@utils/constants';
import { appendColon } from '@utils/string';
import { EnergyType } from '@utils/types/common-types';
import classNames from 'classnames';
import styles from './meter-name-cell.module.scss';

/**
 * Common Component to display meter Name Cell in our table, it will show the meter name
 * and the type icon of this meter, and the link to this meter dashboard page
 */
export const MeterNameCell = ({
  installation_name,
  installation_energy,
  isDraft,
  hasMeter,
  client_id,
  erpCode,
  href,
  linkToTab,
  hasLocation = true,
  muted = false,
}: {
  hasMeter?: boolean | null;
  installation_name: string;
  installation_energy?: EnergyType | null;
  isDraft?: boolean;
  erpCode?: string;
  client_id?: string;
  hasLocation?: boolean;
  muted?: boolean;
  href?: string;
  linkToTab?: string;
}) => {
  const { t } = useTranslation();
  const user = useUser();

  const hrefLink = getHref(user, installation_name, href, installation_energy, hasMeter, linkToTab);

  return (
    <Tooltip tooltip={erpCode ? `${appendColon(t('owner.erp_code'), user.locale)} ${erpCode}` : null}>
      <div className={styles['meter-name-cell']}>
        {/* eslint-disable-next-line */}
        <a className={classNames({ [styles['muted']]: muted })} {...(hrefLink ? { href: hrefLink } : {})}>
          {installation_energy && (
            <span className="type-icon mr-2">
              <Icon name={iconDictionary[installation_energy]} size={14} />
            </span>
          )}
          <span className="installation-name">{installation_name}</span>
          {isDraft && <Badge className="ml-2 d-flex align-items-center" variant="danger" label="common.draft" />}
          {!hasLocation && <span className="fw:200">&nbsp;({t('monitoring.installation.no_location')})</span>}
        </a>
        {client_id && <div className="mt-2 text-grey fw:300">{client_id}</div>}
      </div>
    </Tooltip>
  );
};

const getHref = (
  user: UserCtxType,
  installationName: string,
  href?: string,
  energy?: EnergyType | null,
  hasMeter?: boolean | null,
  linkToTab?: string,
) => {
  const userIsSalesFocusOnly =
    user.isAuthorized(['display:view_salesfocus']) && !user.isAuthorized(['display:view_monitoring']);

  if (user.isOnlyUnavailabilities) {
    return null;
  }

  if (href) {
    return href;
  }

  if (energy && ['solar', 'wind', 'hydro'].includes(energy)) {
    return `/v2/dashboard_meter/?${
      userIsSalesFocusOnly || hasMeter === false ? 'installation' : 'meter'
    }=${encodeURIComponent(installationName)}${linkToTab ? `#${linkToTab}` : ''}`;
  }
};
