import React, { useCallback, useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { Modal } from '@GDM/Modal';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { destroy, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { iconDictionary } from '@utils/constants';
import { edit_v2_installation_path, v2_installation_path } from '@utils/routes';
import { InstallationWithMeterInfo as Installation } from '@utils/types/installation';
import { useInstallationsPageContext } from '../../context/installations-page.context';
import styles from './installations.table.module.scss';

export const InstallationsTab = ({ isLight }: { isLight?: boolean }) => {
  const { t } = useTranslation();
  const { filteredInstallations, selectedInstallation, deleteInstallation, loading } = useInstallationsPageContext();
  const deleteRequest = useRequest(v2_installation_path(selectedInstallation?.name ?? 'none'), destroy, true);
  const [visible, setVisible] = useState(false);
  const toggle = useCallback(() => setVisible((prevVisible) => !prevVisible), [setVisible]);

  const columns = useColumns(toggle, isLight);

  const table = useReactTable({
    columns,
    data: filteredInstallations,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    initialState: { sorting: [{ id: 'name', desc: false }] },
  });

  const handleDeleteClick = () => {
    deleteRequest.execute?.();
    if (selectedInstallation) deleteInstallation?.(selectedInstallation);
    toggle();
  };

  return (
    <div className={styles['size-table']}>
      <Modal
        isOpen={visible}
        size="sm"
        toggle={toggle}
        submitAction={handleDeleteClick}
        saveButtonText={t('common.delete')}
        data-cy="meter-delete-modal"
      >
        {t('admin.installations.are_you_sure_delete', { meterName: selectedInstallation?.name || '' })}
      </Modal>

      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} data-cy="meters-table-body" isLoading={loading} />
      </Table>
    </div>
  );
};

const useColumns = (toggle: () => void, isLight?: boolean) => {
  const { t } = useTranslation();
  const { setSelectedInstallation } = useInstallationsPageContext();

  const openDeleteConfirmationModal = useCallback(
    (installation: Installation) => (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setSelectedInstallation(installation);
      toggle();
    },
    [toggle, setSelectedInstallation],
  );

  const columns = useMemo<ColumnDef<Installation>[]>(() => {
    const baseColumns: ColumnDef<Installation>[] = [
      {
        header: () => <div className="w-1">{t('common.country')}</div>,
        accessorKey: 'country',
        cell: ({ row: { original } }) => (
          <Icon
            size={14}
            name={original.country || 'XX'}
            title={original.country || 'errors.installation.unknown_country'}
          />
        ),
      },
      {
        header: 'common.energy',
        id: 'energy',
        accessorFn: (row) => row.energy,
        cell: ({ row: { original } }) => (original ? <Icon size={14} name={iconDictionary[original.energy]} /> : null),
      },
      {
        header: 'common.name',
        accessorKey: 'name',
        cell: ({ row: { original }, getValue }) => {
          return (
            <div className="d-flex align-items-center">
              <span>{getValue<string>()}</span>
              {original.status === 'draft' && <Badge className="ml-2" label="common.draft" variant="danger" />}
            </div>
          );
        },
      } satisfies ColumnDef<Installation>,
      {
        header: 'common.meter_ref',
        accessorFn: (row) => row.meter?.prm || row.meter?.ref,
      },
      { header: 'common.grid', accessorFn: (row) => row.meter?.grid_name },
    ];

    if (!isLight) {
      baseColumns.push(
        ...([
          { header: 'common.address', accessorKey: 'address', sortDescFirst: false },
          {
            header: '',
            id: 'actions',
            enableSorting: false,
            cell: ({ row }) => (
              <>
                <Restricted permissions={['update:installation']}>
                  <Button
                    floating
                    variant="primary-2"
                    href={edit_v2_installation_path(row.original.name)}
                    data-cy={`edit-meter-button-${row.original.name}`}
                    icon="Edit3"
                  />
                </Restricted>
                <Restricted permissions={['delete:installation']}>
                  <Button
                    floating
                    variant="secondary"
                    className="ml-1"
                    onClick={openDeleteConfirmationModal(row.original)}
                    data-cy={`delete-meter-button-${row.original.name}`}
                    icon="Trash2"
                  />
                </Restricted>
              </>
            ),
          } satisfies ColumnDef<Installation>,
        ] satisfies ColumnDef<Installation>[]),
      );
    }

    return baseColumns;
  }, [openDeleteConfirmationModal, t, isLight]);

  return columns;
};
