import React from 'react';
import {
  BillableProdCell,
  CheckboxCell,
  ContractCell,
  DeltaProdVsDevice,
  DeltaProdVsIndexCell,
  InstallationCell,
  InvoiceTypeCell,
  IssuanceStatusCell,
  LoadcurveCell,
  NoWrapHeadCell,
  SourceCell,
  StatusCell,
  UnitPriceCell,
} from '@components/invoices/Table/Cells';
import { TotalAmountCell } from '@components/invoices/Table/Cells/TotalAmountCell';
import { UserCtxType } from '@context/User.context';
import { CurrencyCell, DateCell, NumberCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import ProgressPercentCell from '@ui/table/cell/progress-percent-cell';
import absoluteSortTable from '@utils/absoluteSortTable';
import { Locale } from '@utils/types/common-types';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import compact from 'lodash/compact';
import { ActionCell } from './Cells/ActionCell';
import styles from './invoice-table.module.scss';

const renderHeader =
  (label: string, tooltip = 'kWh', className?: string) =>
  () => {
    return <NoWrapHeadCell tooltip={tooltip} translationKey={label} className={className} />;
  };

const getColumns = (
  type: MeterInvoiceSource,
  direction: Contract['direction'] | 'all',
  options: {
    locale: Locale;
    isModal: boolean;
    isTableExpanded: boolean;
    isLight: boolean;
    user: UserCtxType;
    setHistoryModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setClickedInvoice?: (invoice: MeterInvoice) => void;
    handleEditClick: (invoice: MeterInvoice) => void;
  },
): ColumnDef<MeterInvoice>[] => {
  const volumeColClassnames = styles['invoice-volumes-col'];
  const expandedColumnsClassName = options.isTableExpanded || options.isModal ? volumeColClassnames : 'd-none';

  const CHECKBOX_COLUMN = {
    header: '',
    id: 'invoice_selection_checkbox',
    cell: CheckboxCell,
    disableSortBy: true,
  };

  const TYPE_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'Type',
    accessorKey: 'internal_type',
    cell: InvoiceTypeCell,
  };

  const INSTALLATION_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'installation_name',
    header: 'common.installation',
    id: 'installation_name',
    cell: InstallationCell,
  };

  const SOURCE_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'monitoring.invoicing.source',
    meta: { className: expandedColumnsClassName },
    cell: SourceCell,
  };

  const PROD_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_production',
    id: 'volume_production',
    header: renderHeader('monitoring.invoicing.prod_billable'),
    meta: { className: volumeColClassnames },
    cell: BillableProdCell,
  };

  const CONS_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_production',
    id: 'volume_consumption',
    header: renderHeader('monitoring.invoicing.cons_billable'),
    meta: { className: volumeColClassnames },
    cell: BillableProdCell,
  };

  const FIRST_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: renderHeader('sales_management.first_index'),
    id: 'volume_first_index',
    accessorKey: 'volume_first_index',
    meta: { className: expandedColumnsClassName },
    cell: (props) => <NumberCell {...props} />,
  };

  const LAST_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: renderHeader('sales_management.last_index'),
    id: 'volume_last_index',
    meta: { className: expandedColumnsClassName },
    accessorKey: 'volume_last_index',
    cell: (props) => <NumberCell {...props} />,
  };

  const FIRST_CONSO_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: renderHeader('sales_management.first_consumption_index'),
    id: 'volume_first_conso_index',
    accessorKey: 'volume_first_conso_index',
    meta: { className: expandedColumnsClassName },
    cell: (props) => <NumberCell {...props} />,
  };

  const LAST_CONSO_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: renderHeader('sales_management.last_consumption_index'),
    id: 'volume_last_conso_index',
    accessorKey: 'volume_last_conso_index',
    meta: { className: expandedColumnsClassName },
    cell: (props) => <NumberCell {...props} />,
  };

  const START_DATE_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'common.start_date',
    accessorKey: 'start_date',
    id: 'start_date',
  };

  const END_DATE_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'common.end_date',
    accessorKey: 'end_date',
    id: 'end_date',
  };

  const VALIDATION_PROD_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_status',
    header: 'sales_management.prod_validated',
    meta: { className: volumeColClassnames },
    cell: StatusCell,
  };

  const ISSUANCE_STATUS_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'common.status_label',
    accessorKey: 'status',
    cell: IssuanceStatusCell,
  };

  const DELTA_PROD_VS_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_delta_prod_vs_index',
    id: 'volume_delta_prod_vs_index',
    header: renderHeader('Δ Index'),
    meta: { className: expandedColumnsClassName },
    cell: DeltaProdVsIndexCell,
    sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
  };

  const DELTA_PROD_VS_CORRECTED_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_delta_prod_vs_corrected',
    id: 'volume_delta_prod_vs_corrected',
    header: renderHeader('common.delta_prod_corrected'),
    meta: { className: expandedColumnsClassName },
    cell: (props) => <LoadcurveCell {...props} type="corrected" />,
    sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
  };

  const DELTA_PROD_VS_DEVICE_COLUMN: ColumnDef<MeterInvoice> = {
    id: 'scada',
    accessorKey: 'volume_delta_prod_vs_device',
    header: renderHeader('Δ Scada'),
    meta: { className: expandedColumnsClassName },
    cell: DeltaProdVsDevice,
    sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
  };

  const DELTA_PROD_VS_LOADCURVE_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_delta_prod_vs_load_curve',
    id: 'volume_delta_prod_vs_load_curve',
    header: renderHeader('monitoring.invoicing.raw_curve'),
    meta: { className: expandedColumnsClassName },
    cell: (props) => <LoadcurveCell {...props} type="raw" />,
    sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
  };

  const COVERAGE_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_coverage',
    id: 'volume_coverage',
    header: () => <NoWrapHeadCell translationKey="monitoring.invoicing.dispo_enedis" />,
    meta: { className: expandedColumnsClassName },
    cell: ProgressPercentCell,
  };

  const CONSUMPTION_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_consumption',
    id: 'volume_consumption',
    header: renderHeader('monitoring.invoicing.consum'),
    meta: { className: expandedColumnsClassName },
    cell: (props) => <NumberCell {...props} />,
  };

  const UPDATED_AT_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'updated_at',
    header: 'monitoring.invoicing.update',
    meta: { className: expandedColumnsClassName },
    cell: ({ row: { original } }) => (
      <DateCell
        value={original.volume_updated_at > original.updated_at ? original.volume_updated_at : original.updated_at}
      />
    ),
  };

  const CONS_UPDATED_AT_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'updated_at',
    header: 'monitoring.invoicing.cons_update',
    meta: { className: volumeColClassnames },
    cell: ({ row: { original } }) => (
      <DateCell
        value={original.volume_updated_at > original.updated_at ? original.volume_updated_at : original.updated_at}
      />
    ),
  };

  const CONTRACT_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'contract_type',
    header: 'common.contract',
    id: 'invoice_contract_type',
    cell: ContractCell,
  };

  const PRICE_PER_MWH_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'sales_management.price_label',
    id: 'unit_price',
    accessorKey: 'unit_price',
    cell: UnitPriceCell,
  };

  const TOTAL_AMOUNT_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'sales_management.total_amount',
    id: 'total_amount',
    accessorKey: 'total_amount',
    cell: TotalAmountCell,
  };

  const PRICE_PER_MW_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'unit_price',
    header: 'sales_management.price_label',
    id: 'unit_price',
    cell: ({ getValue, row: { original } }) => (
      <NumberCell
        value={getValue<number>()}
        numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
        unit={`${original.currency}/MW`}
      />
    ),
  };

  const TOTAL_REVENUE_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'total_amount',
    header: 'sales_management.total_amount',
    cell: ({ getValue, row: { original } }) => (
      <CurrencyCell value={getValue<number>()} showUnit currency={original.currency} />
    ),
  };

  const CAPA_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'nb_capa',
    header: renderHeader('sales_management.ncc', 'MW'),
    cell: ({ getValue }) => (
      <NumberCell value={getValue()} numberOptions={{ minimumFractionDigits: 5, maximumFractionDigits: 10 }} />
    ),
  };

  const REC_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_production',
    header: renderHeader('invoice.volume', 'kWh'),
    id: 'volume',
    cell: (props) => <NumberCell {...props} />,
  };

  const ACTIONS_COLUMN: ColumnDef<MeterInvoice> = {
    id: 'actions',
    cell: (props) => (
      <ActionCell
        {...props}
        setClickedInvoice={options.setClickedInvoice}
        handleEditClick={options.handleEditClick}
        setHistoryModalIsOpen={options.setHistoryModalIsOpen}
        tabType={type}
      />
    ),
  };

  const colsAtTop: ColumnDef<MeterInvoice>[] = [];

  if (!options.isModal && !options.isLight) {
    colsAtTop.unshift(
      ...[
        CHECKBOX_COLUMN,
        INSTALLATION_COLUMN,
        CONTRACT_COLUMN,
        TYPE_COLUMN,
        ISSUANCE_STATUS_COLUMN,
        START_DATE_COLUMN,
        END_DATE_COLUMN,
      ],
    );
  } else if (options.isLight) {
    colsAtTop.unshift(...[CHECKBOX_COLUMN, CONTRACT_COLUMN, START_DATE_COLUMN, END_DATE_COLUMN]);
  }

  if (type === 'load_curve' || type === 'yearly_regul') {
    if (direction === 'buy') {
      return compact([
        !options.isModal && CHECKBOX_COLUMN,
        !options.isLight && INSTALLATION_COLUMN,
        CONTRACT_COLUMN,
        TYPE_COLUMN,
        ISSUANCE_STATUS_COLUMN,
        START_DATE_COLUMN,
        END_DATE_COLUMN,
        CONS_COLUMN,
        CONS_UPDATED_AT_COLUMN,
        PRICE_PER_MWH_COLUMN,
        TOTAL_AMOUNT_COLUMN,
        ACTIONS_COLUMN,
      ]);
    }

    return compact([
      !options.isModal && CHECKBOX_COLUMN,
      !options.isLight && INSTALLATION_COLUMN,
      CONTRACT_COLUMN,
      TYPE_COLUMN,
      ISSUANCE_STATUS_COLUMN,
      START_DATE_COLUMN,
      END_DATE_COLUMN,
      PROD_COLUMN,
      DELTA_PROD_VS_INDEX_COLUMN,
      DELTA_PROD_VS_LOADCURVE_COLUMN,
      DELTA_PROD_VS_CORRECTED_COLUMN,
      DELTA_PROD_VS_DEVICE_COLUMN,
      COVERAGE_COLUMN,
      CONSUMPTION_COLUMN,
      SOURCE_COLUMN,
      UPDATED_AT_COLUMN,
      VALIDATION_PROD_COLUMN,
      PRICE_PER_MWH_COLUMN,
      TOTAL_AMOUNT_COLUMN,
      ACTIONS_COLUMN,
    ]);
  } else if (type === 'rec') {
    return compact([...colsAtTop, REC_COLUMN, PRICE_PER_MW_COLUMN, TOTAL_AMOUNT_COLUMN, ACTIONS_COLUMN]);
  } else if (type === 'capa') {
    return compact([...colsAtTop, CAPA_COLUMN, PRICE_PER_MW_COLUMN, TOTAL_REVENUE_COLUMN, ACTIONS_COLUMN]);
  } else {
    const indexCols = [
      !options.isModal && CHECKBOX_COLUMN,
      !options.isLight && INSTALLATION_COLUMN,
      CONTRACT_COLUMN,
      TYPE_COLUMN,
      ISSUANCE_STATUS_COLUMN,
      START_DATE_COLUMN,
      END_DATE_COLUMN,
      PROD_COLUMN,
      CONSUMPTION_COLUMN,
      SOURCE_COLUMN,
      FIRST_INDEX_COLUMN,
      LAST_INDEX_COLUMN,
      FIRST_CONSO_INDEX_COLUMN,
      LAST_CONSO_INDEX_COLUMN,
      UPDATED_AT_COLUMN,
      VALIDATION_PROD_COLUMN,
      PRICE_PER_MWH_COLUMN,
      TOTAL_AMOUNT_COLUMN,
      ACTIONS_COLUMN,
    ];

    return compact(indexCols);
  }
};

export default getColumns;
