import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { useFormContext, Controller } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const ProductTypeFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts } = useConsumeContracts();

  const options: Option<ContractsFiltersType['product_type'] | null>[] = [
    { value: 'power', label: 'contracts.product_type.power' },
    { value: 'opex', label: 'contracts.product_type.opex' },
  ];

  return (
    <Controller
      control={form.control}
      name="product_type"
      render={({ field }) => (
        <Select
          isClearable
          placeholder="contracts.product_type.product_type"
          options={options}
          selectedOption={field.value}
          onChange={(option) => {
            const value = option?.value || null;
            field.onChange(value);
            filterContracts({
              ...form.getValues(),
              product_type: value,
              last_selected_filter: 'product_type',
            });
            form.setValue('last_selected_filter', 'product_type');
          }}
        />
      )}
    />
  );
};
