import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';
import dayjs from 'dayjs';

export const Horizon = ({ row: { original } }: CellContext<Trade, unknown>) => {
  const format = 'DD MMM YY';

  return (
    <div>
      {dayjs(original.start_date).format(format)} {'>'} {dayjs(original.client_end_date).format(format)}
    </div>
  );
};
