import React, { useCallback, useEffect, useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Modal } from '@GDM/Modal';
import { post, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getLocalizedMonthList } from '@utils/getLocalizedMonthList';
import { create_batch_v2_installation_asset_bps_path } from '@utils/routes';
import { BusinessPlan } from '@utils/types/business-plan';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { AllYearsFormField } from './AllYearsFormField';
import { MonthFormField, Month } from './MonthFormField';
import { YearFormField, defaultYearRange } from './YearFormField';

export type FormType = {
  year: string;
  allYears: boolean; // submit the form for all years
  JAN: string;
  FEB: string;
  MAR: string;
  APR: string;
  MAY: string;
  JUN: string;
  JUL: string;
  AUG: string;
  SEP: string;
  OCT: string;
  NOV: string;
  DEC: string;
};

type Props = {
  businessPlans: Record<string, BusinessPlan[]>;
  setBusinessPlans: React.Dispatch<React.SetStateAction<Record<number, BusinessPlan[]>>>;
  installationName: string;
  modalVisible: boolean;
  toggleModal: () => void;
};

export const BusinessPlanForm = ({
  businessPlans,
  setBusinessPlans,
  installationName,
  modalVisible,
  toggleModal,
}: Props) => {
  const { locale } = useUser();
  const { t } = useTranslation();

  const months = useMemo(
    () => ({
      en: getLocalizedMonthList('en') as Array<Month>,
      localized: getLocalizedMonthList(locale),
    }),
    [locale],
  );

  const {
    watch,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ values: getFormValues(dayjs().year().toString(), businessPlans, months) });

  const allYearsEnabled = watch('allYears');
  const year = watch('year');
  const yearBusinessPlans = useMemo(() => businessPlans[year] || [], [businessPlans, year]);

  const request = useRequest<BusinessPlan[]>(create_batch_v2_installation_asset_bps_path(installationName), post, true);

  const onSubmit = useCallback(() => {
    const formData = getValues();
    let body: { production: number; start_date: string }[] = [];

    if (formData['allYears']) {
      const allYears = new Set([
        ...Object.keys(businessPlans || {}),
        ...defaultYearRange.map((_year) => _year.toString()),
      ]);

      allYears.forEach((_year) => {
        body = body.concat(
          months.en.map((m, i) => ({
            production: +formData[m],
            start_date: dayjs(new Date(Number(_year), i, 1)).format('YYYY-MM-DD'),
          })),
        );
      });
    } else {
      body = months.en.map((m, i) => ({
        production: +formData[m],
        start_date: dayjs(new Date(Number(formData['year']), i, 1)).format('YYYY-MM-DD'),
      }));
    }

    request.execute?.({ business_plans: body });
  }, [getValues, months.en, request, businessPlans]);

  useEffect(() => {
    if (!modalVisible) reset();
  }, [modalVisible, reset]);

  useEffect(() => {
    if (request.loaded && !request.error && request.data) {
      setBusinessPlans((businessPlans) => {
        const _businessPlans = cloneDeep(Object.values(businessPlans).flatMap((__businessPlans) => __businessPlans));

        (request.data || []).forEach((datum) => {
          const index = _businessPlans.findIndex((businessPlan) => businessPlan.start_date == datum.start_date);
          if (index > -1) _businessPlans[index] = datum;
          else _businessPlans.push(datum);
        });

        const _businessPlansGrouped = groupBy(_businessPlans, (businessPlan) => dayjs(businessPlan.start_date).year());

        if (isEqual(businessPlans, _businessPlansGrouped)) return businessPlans;
        else return _businessPlansGrouped;
      });
      toggleModal();
    }
  }, [request.data, request.loaded, request.error, toggleModal, setBusinessPlans]);

  useEffect(() => {
    const subscription = watch(({ year }, event) => {
      if (year && event.name === 'year' && event.type === 'change') {
        reset(getFormValues(year, businessPlans, months));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, getValues, reset, months, setValue, businessPlans]);

  const Header = (
    <div className="d-flex">
      <div className="mr-2">
        {yearBusinessPlans ? `${t('monitoring.bp.edit_a_bp')}` : `${t('monitoring.bp.add_a_bp')}`}
      </div>
      <YearFormField control={control} isDisabled={allYearsEnabled} />
    </div>
  );

  return (
    <Modal
      isOpen={modalVisible}
      toggle={toggleModal}
      header={Header}
      saveButtonText="common.save"
      isLoading={request.loading}
      submitDisabled={request.loading}
      submitAction={handleSubmit(onSubmit)}
      error={request?.error?.message}
      footer={<AllYearsFormField control={control} />}
    >
      {months.en.map((month: Month, i) => (
        <MonthFormField
          key={`business-plans-form-${month}`}
          month={month}
          errors={errors}
          control={control}
          label={months.localized[i]}
        />
      ))}
    </Modal>
  );
};

const getFormValues = (
  year: string,
  businessPlans: Record<string, BusinessPlan[]>,
  months: { en: Month[]; localized: string[] },
): FormType => {
  const defaultValues = {
    year,
    allYears: false,
    JAN: '',
    FEB: '',
    MAR: '',
    APR: '',
    MAY: '',
    JUN: '',
    JUL: '',
    AUG: '',
    SEP: '',
    OCT: '',
    NOV: '',
    DEC: '',
  };

  const yearBusinessPlans = businessPlans[defaultValues.year] || [];

  for (let i = 0; i < 12; i++) {
    const production = yearBusinessPlans.find(({ start_date }) => dayjs(start_date).month() === i)?.production;

    if (defaultValues[months.en[i]] !== undefined) {
      defaultValues[months.en[i]] = production?.toString() || '';
    }
  }

  return defaultValues;
};
