import React from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import { useUserContext } from '@context/User.context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import Notifications from '../Notifications';
import styles from './searchbar.module.scss';
import SearchbarInput from './SearchbarInput';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Searchbar = ({ user }: { user: User }) => {
  const { isOnlyUnavailabilities } = useUserContext(user);

  if (isOnlyUnavailabilities) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider user={user}>
        <div className={styles['searchbar']}>
          <SearchbarInput />
          <Notifications />
        </div>
      </UserProvider>
    </QueryClientProvider>
  );
};

export default Searchbar;
