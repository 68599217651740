import { ContractTableFilters } from '../contracts.types';

const TABLE_COLUMN_FILTER_KEY = 'contracts.table-column-filter';

function getTableColumnFilters(): ContractTableFilters | null {
  const item = localStorage.getItem(TABLE_COLUMN_FILTER_KEY);

  if (!item) return null;
  const parsedItem = JSON.parse(item);

  return itemIsColumnFilters(parsedItem) ? parsedItem : null;
}

function setTableColumnFilters(filters: ContractTableFilters): void {
  localStorage.setItem(TABLE_COLUMN_FILTER_KEY, JSON.stringify(filters));
}

const itemIsColumnFilters = (item: unknown): item is ContractTableFilters => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'clientId' in item &&
    'commissioningDate' in item &&
    'sellerSiren' in item &&
    'cardI' in item &&
    'buyer' in item &&
    'p_max' in item &&
    'country' in item &&
    'pricing' in item &&
    'seller' in item &&
    'horizon' in item
  );
};

export const DEFAULT_FILTERS: ContractTableFilters = {
  clientId: false,
  commissioningDate: false,
  sellerSiren: false,
  cardI: false,
  buyer: true,
  p_max: true,
  country: true,
  pricing: true,
  seller: true,
  horizon: true,
  classification: true,
  volume: true,
  product_type: true,
};

export const localStorageHelpers = {
  getTableColumnFilters,
  setTableColumnFilters,
};
