import { createContext, useContext } from 'react';
import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { RequestError } from '@hooks/useRequest';
import { ContractTableFilters } from '@pages/Contracts/Contracts/contracts.types';
import { Contract } from '@utils/types/contract';

export type ContractsContextType = {
  isLoading?: boolean;
  error?: RequestError | null;
  contracts: Contract[];
  allContracts: Contract[];
  addContract: (contract: Contract) => void;
  removeContract?: (contract: Contract) => Promise<true | undefined>;
  updateContract: (contract_id: string, contract: Contract) => void;
  filterContracts: (filters: ContractsFiltersType) => void;
  selectedContract?: Contract | null;
  setSelectedContract?: (contract: Contract | null) => void;
  replaceContracts: (contracts: Contract[]) => void;
  selectedToDeleteId: string | null;
  setSelectedToDeleteId: (id: string | null) => void;
  isDeleting: boolean;
  deleteError: string | null;
  resetDeleteError: () => void;
  infoFilters: ContractTableFilters;
  setInfoFilters: (filters: Partial<ContractTableFilters>) => void;
};

const contractsContext = createContext<ContractsContextType>({
  contracts: [],
  allContracts: [],
  filterContracts: () => {},
  addContract: () => {},
  updateContract: () => {},
  selectedContract: null,
  setSelectedContract: () => {},
  replaceContracts: () => {},
  selectedToDeleteId: null,
  setSelectedToDeleteId: () => {},
  isDeleting: false,
  deleteError: null,
  resetDeleteError: () => {},
  infoFilters: {
    clientId: false,
    commissioningDate: false,
    sellerSiren: false,
    cardI: false,
    buyer: false,
    seller: false,
    p_max: false,
    country: false,
    horizon: false,
    pricing: false,
    classification: true,
    volume: true,
    product_type: false,
  },
  setInfoFilters: () => {},
});

export const useConsumeContracts = (): ContractsContextType => useContext(contractsContext);

export default contractsContext;
