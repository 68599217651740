import React, { useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import { CharacteristicsSection } from '../../shared/CharacteristicsSection/CharacteristicsSection';
import ModalForm from './ModalForm';

const Inverters = ({ meterName }: { meterName: string }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const toggle = () => {
    setVisible((v) => !v);
  };

  return (
    <CharacteristicsSection
      title={
        <CharacteristicsSection.Title title="common.inverters">
          <Restricted permissions={['display:edit_monitoring']}>
            <Button className="mr-0" variant="primary-2" size="xs" onClick={toggle}>
              {t('common.edit')}
            </Button>
          </Restricted>
        </CharacteristicsSection.Title>
      }
    >
      <ModalForm toggle={toggle} visible={visible} meterName={meterName} />
    </CharacteristicsSection>
  );
};

export default Inverters;
