import { useMemo } from 'react';
import useTranslation, { type TranslateFn } from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import type Installation from '@utils/types/installation';
import type MarketPlayer from '@utils/types/market_player';
import type { User } from '@utils/types/user';

export type OptionWithMarketPlayerType = Option<string> & { type?: MarketPlayer['player_type'] };

export const useContractPartiesOptions = (
  accountType: User['account_type'],
  marketPlayers: MarketPlayer[],
  linkedMarketPlayers: MarketPlayer[],
  intermediaries: MarketPlayer[],
  installation: Installation | null,
): {
  myEntitiesOptions: OptionWithMarketPlayerType[];
  counterpartsOptions: OptionWithMarketPlayerType[];
} => {
  const { t } = useTranslation();

  return useMemo(() => {
    return getFilteredOptions(accountType, intermediaries, marketPlayers, linkedMarketPlayers, installation, t);
  }, [accountType, intermediaries, marketPlayers, linkedMarketPlayers, installation, t]);
};

type GetOptions = (
  accountType: User['account_type'],
  allMarketPlayersOptions: OptionWithMarketPlayerType[],
  linkedMarketPlayersOptions: OptionWithMarketPlayerType[],
  intermediariesOptions: OptionWithMarketPlayerType[],
  installationOwnerOption: Option<string> | null,
) => OptionWithMarketPlayerType[];

export const getCounterparts: GetOptions = (
  accountType,
  allMarketPlayersOptions,
  _, // linkedMarketPlayersOptions,
  intermediariesOptions,
  installationOwnerOption,
) => {
  if (accountType === 'aggregator') {
    return [installationOwnerOption].filter(filterOutNullOption);
  }

  if (accountType === 'producer') {
    return [
      ...intermediariesOptions,
      ...allMarketPlayersOptions.filter(({ type }) =>
        (['offtaker', 'corporate'] as OptionWithMarketPlayerType['type'][]).includes(type),
      ),
    ];
  }

  return [];
};

export const getMyEntities: GetOptions = (
  accountType,
  _, // allMarketPlayersOptions,
  linkedMarketPlayersOptions,
  intermediariesOptions,
  installationOwnerOption,
) => {
  if (accountType === 'aggregator') {
    return [
      ...linkedMarketPlayersOptions.filter(({ type }) =>
        (['offtaker', 'corporate', 'intermediary'] as OptionWithMarketPlayerType['type'][]).includes(type),
      ),
    ].filter(filterOutNullOption);
  }

  if (accountType === 'producer') {
    return [installationOwnerOption, ...intermediariesOptions].filter(filterOutNullOption);
  }

  return [];
};

const marketPlayerToOption = (marketPlayer: MarketPlayer) => ({
  label: marketPlayer.short_name,
  value: marketPlayer.id,
  type: marketPlayer.player_type,
});

const filterOutNullOption = (value: OptionWithMarketPlayerType | null): value is OptionWithMarketPlayerType =>
  Boolean(value);

export const getDefaultValue = (
  options: OptionWithMarketPlayerType[],
  offtaker: MarketPlayer | null,
  installation: InstallationWithMeterInfo | null,
) => {
  if (options.length === 1) {
    return options[0].value;
  }

  if (offtaker && options.find(({ value }) => value === offtaker.id)) {
    return offtaker.id;
  }

  if (installation && options.find(({ value }) => value === installation.owner_uuid)) {
    return installation.owner_uuid;
  }

  return null;
};

export const getBaseOptions = (
  intermediaries: MarketPlayer[],
  marketPlayers: MarketPlayer[],
  linkedMarketPlayers: MarketPlayer[],
  installation: Installation | null,
  t: TranslateFn,
) => {
  const installationCountry = installation?.country || 'FR';

  const intermediariesOptions = intermediaries.map(marketPlayerToOption);
  const linkedMarketPlayersOptions = linkedMarketPlayers.map(marketPlayerToOption);
  const allMarketPlayersOptions = marketPlayers
    .filter(
      (marketPlayer) =>
        marketPlayer.country === installationCountry || marketPlayer.operating_countries?.includes(installationCountry),
    )
    .map(marketPlayerToOption);

  const installationOwnerOption =
    installation && installation.owner_uuid
      ? {
          label: `${installation?.owner_short_name || ''} (${t('sales_management.owner')})`.trim(),
          value: installation.owner_uuid,
        }
      : null;

  return {
    allMarketPlayersOptions,
    linkedMarketPlayersOptions,
    intermediariesOptions,
    installationOwnerOption,
  };
};

export const getFilteredOptions = (
  accountType: User['account_type'],
  intermediaries: MarketPlayer[],
  marketPlayers: MarketPlayer[],
  linkedMarketPlayers: MarketPlayer[],
  installation: Installation | null,
  t: TranslateFn,
) => {
  const { intermediariesOptions, allMarketPlayersOptions, linkedMarketPlayersOptions, installationOwnerOption } =
    getBaseOptions(intermediaries, marketPlayers, linkedMarketPlayers, installation, t);

  const counterparts = getCounterparts(
    accountType,
    allMarketPlayersOptions,
    linkedMarketPlayersOptions,
    intermediariesOptions,
    installationOwnerOption,
  );

  const myEntities = getMyEntities(
    accountType,
    allMarketPlayersOptions,
    linkedMarketPlayersOptions,
    intermediariesOptions,
    installationOwnerOption,
  );

  return {
    myEntitiesOptions: myEntities.sort(sortOptionsByLabelAsc),
    counterpartsOptions: counterparts.sort(sortOptionsByLabelAsc),
  };
};
