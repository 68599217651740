import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import type { Option } from '@utils/types/common-types';
import type { TimeSeries } from '@utils/types/timeSeries';
import { type UseFormReturn } from 'react-hook-form';
import type { ProductionFilters } from '../Production';

export const MarketPriceCurveSelect = ({
  filtersForm,
  marketPrices,
}: {
  filtersForm: UseFormReturn<ProductionFilters>;
  marketPrices?: Record<string, TimeSeries>;
}) => {
  const marketPricesOptions: Option<ProductionFilters['selectedMarketPriceCurve']>[] = [
    ...Object.keys(marketPrices || {}).map((key) => {
      const { country, label } = exportCountrySourceFromKey(key);

      return { label, value: key, country };
    }),
  ];

  if (marketPricesOptions.length < 2) return null;

  return (
    <ControlledSelect
      control={filtersForm.control}
      name="selectedMarketPriceCurve"
      options={marketPricesOptions}
      isCountry
    />
  );
};

export const exportCountrySourceFromKey = (key: string) => {
  const parts = key.split('_');
  if (parts.length == 3) {
    // e.g. IT_NORD_GME
    const country = parts[0].toUpperCase();
    const region = parts[1].toUpperCase();
    const source = parts[2].toUpperCase();
    let powerExchange = source;
    if (source === 'ENTSOE' && country === 'IT') powerExchange = 'GME';

    return { country, label: `${powerExchange} (${region})` };
  }

  if (parts[0] === 'IE' && parts[1] === 'ENTSOE') return { country: parts[0], label: 'SEM' };

  return { country: parts[0], label: mapType(parts[1]) };
};

const mapType = (type: string) => {
  if (type === 'ENTSOE') return 'EPEX';
  if (type === 'GME') return 'PUN';

  return type;
};
